import { Component, OnInit } from '@angular/core';
import { ProductAdminService } from '../product-admin-service/product-admin.service';

import { Location } from '@angular/common';
import * as _ from 'lodash';
import { OrderManagementService } from '../payments/order-management-service/order-management.service';
import { AuthService } from '../auth/auth.service';
import { CartLineItemModel } from '../payments/models/cart-line-item-model';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-site-home-page',
  templateUrl: './site-home-page.component.html',
  styleUrls: ['./site-home-page.component.scss']
})
// tslint:disable-next-line:component-class-suffix
export class SiteHomePage implements OnInit {

  url: String;
  loading = true;
  products: any[] = [];

  constructor(
    private authService: AuthService,
    private productAdminServ: ProductAdminService,
    private orderMgmtServ: OrderManagementService,
    private location: Location
  ) {

    // This can probably go in OnInit
    this.url = `https://asharplight-one.firebaseapp.com/${this.location.path()}`;
    this.url = '/';
  }


  ngOnInit() {
    console.log('In Home page - Getting the list or Product Models ');

    console.log(' The location path is: ', this.location.path());
    console.log(' The full URL is : ', this.url);

    this.productAdminServ.getAllActiveProductModelsPublic().subscribe(theModelsList => {

      this.products = theModelsList;

      this.loading = false;

    });
  }

  getThisProduct(theSKU: string): any {

    return _.find(this.products, ['sku', theSKU]);

  }

  buyNowForProduct(theProdDetails: any) {
    console.log('User hit buy now for product: ');
    console.log(theProdDetails);

    const tempItem: CartLineItemModel = new CartLineItemModel();
    tempItem.sku = theProdDetails.sku;
    tempItem.name = theProdDetails.name;
    tempItem.description = theProdDetails.description;
    tempItem.price = theProdDetails.priceInUSD;
    tempItem.lineItemSubtotal = theProdDetails.priceInUSD;
    tempItem.timestampAddedToCart = new Date();

    this.orderMgmtServ.addLineItemToOpenCartForUser(this.authService.currentUserId(), tempItem);
    // This will create a new order if there is no active order in the DB or in local storage for non logged in users.
  }

}
