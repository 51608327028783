import { Injectable } from '@angular/core';
import { ItemUpload } from './item-upload';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
// import { AngularFireModule } from 'angularfire2';
import * as firebase from 'firebase';
// import { Observable } from 'rxjs';
// import { storage } from 'firebase/app';


@Injectable()
export class ItemUploadService {

  private basePath = 'VideoFiles';
  uploadsRef: AngularFirestoreCollection<ItemUpload>;
  uploadsCollection: AngularFirestoreCollection<ItemUpload> = this.afs.collection(this.basePath); // reference
  // uploads: Observable<ItemUpload[]>;

  constructor(private afs: AngularFirestore) { }


  deleteItemUpload(upload: ItemUpload) {
    this.deleteFileData(upload.uid)
      .then(() => {
        this.deleteFileStorage(upload);
      })
      .catch((error) => console.log(error));
  }

  // Executes the file uploading to firebase https://firebase.google.com/docs/storage/web/upload-files
  pushItemUpload(upload: ItemUpload): Promise<any> {

    const newUid = this.afs.createId();
    upload.uid = newUid;

    const storageRef = firebase.storage().ref();
    // const uploadTask = storageRef.child(`${this.basePath}/${upload.file.name}`).put(upload.file);
    // const uploadTask = storageRef.child(`${this.basePath}/${upload.uid}`).put(upload.file);

    const locationString: string = this.basePath + '/' + upload.owningUid + '/' + upload.uid;
    const uploadTask = storageRef.child(locationString).put(upload.file);

    return new Promise((resolve, reject) => {

      uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot: firebase.storage.UploadTaskSnapshot) => {
          // upload in progress
          const snap = snapshot;
          console.log('uploadTask just popped back a snapshot which is: ', snap);

          upload.bytesTransferred = snap.bytesTransferred;
          upload.size = snap.totalBytes;
          upload.progress = (snap.bytesTransferred / snap.totalBytes) * 100;
        },
        (error) => {
          // upload failed
          console.log('The upload failed: ');
          console.log(error);
        },
        () => {
          console.log('upload may be finished now ... the snapshot is: ');
          console.log(uploadTask.snapshot);

          // need an extra call back to get the download URL (This changed in a firebase update.)
          // upload success

          uploadTask.snapshot.ref.getDownloadURL()
          .then(downloadURL => {
            console.log('The Download URL for the uploaded file is: ', downloadURL);
            upload.url = downloadURL;
            upload.name = uploadTask.snapshot.metadata.name;
            upload.size = uploadTask.snapshot.metadata.size;
            upload.fullPath = uploadTask.snapshot.metadata.fullPath;

            this.saveFileData(upload);
            resolve(upload); // should this be a promise.
          })
          .catch(getDownloadError => {
            console.error('No download URL!');
            console.log('There was an error getting the download URL and it was: ');
            console.log(getDownloadError);
            reject('An error occurred in the file upload - there was an error');
          });
        },
      );
    });
  }

  // Writes the file details to Firestore
  private saveFileData(upload: ItemUpload) {

    return this.uploadsCollection.doc(upload.uid).set({ ...upload.stripTemporaryFields() });
  }

  // Deletes the file details in Firestore
  private deleteFileData(key: string) {
    return this.uploadsCollection.doc(key).delete();
  }

  // Firebase files must have unique names in their respective storage dir
  // So the UID serves as a unique key
  private deleteFileStorage(upload: ItemUpload) {
    const storageRef = firebase.storage().ref();
    const locationString: string = this.basePath + '/' + upload.owningUid + '/' + upload.uid; // could use full path here.
    storageRef.child(locationString).delete();
  }
}
