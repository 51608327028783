

export class ProductModelDataType {

    constructor(

        public uid: string = '',
        public sku: string = '',
        public name: string = '',
        public description: string = '',
        public modelNumber: string = '',
        public weightInGrams: number = 0,
        public weightInOzs: number = 0,
        public priceInUSD: number = 0,
        public priceInCAD: number = 0,
        public hasSerialNumber: boolean = false,
        public isBundle: boolean = false,
        public includedInBundle: string[] = [],
        public dateIntroduced: Date = new Date(),
        public mainPhotoURL: string = '',
        public frontPhotoURL: string = '',
        public rearPhotoURL: string = '',
        public topPhotoURL: string = '',
        public bottomPhotoURL: string = '',
        public groupPhotoURL: string = '',
        public detailPhoto1URL: string = '',
        public detailPhoto2URL: string = '',
        public introVideoURL: string = '',
        public detailedVideoURL: string = '',
        public runningTotalOfModelNumberManufactured: number = 0,
        public isActive: boolean = true,
        public dateDiscontinued: Date = new Date('12-31-2199'),

    ) { }


    public fromJSON(obj: any) {
        // console.log('in the from JSON of the WorkerTestscoreModel and the obj is: ', obj);
        for (const key in obj) {
            if (this.hasOwnProperty(key)) {

                this[key] = obj[key];

            }
        }
    }

}
