import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef, AfterContentInit, OnDestroy } from '@angular/core';

import { OrderModel } from '../models/order-model';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from '../../auth/auth.service';
import { OrderManagementService } from '../order-management-service/order-management.service';
import { map, take } from 'rxjs/operators';
import { FormGroup, FormControl, FormBuilder, Validators, Form, AbstractControl } from '@angular/forms';
import { CartLineItemModel } from '../models/cart-line-item-model';
import { StreetAddressModel } from '../models/street-address-model';
import { AddressMgmtService } from '../../address/address-mgmt-service/address-mgmt.service';
import { MatRadioChange } from '@angular/material';

import { environment } from '../../../environments/environment';
import { TheCheckoutFromShopifyComponent } from '../../the-checkout-from-shopify/the-checkout-from-shopify.component';
import { GlobalUtilitiesService } from '../../utils/global-utils.service';
import { Router } from '@angular/router';
import { PromoCodeUserService } from '../../promo-code-user-service/promo-code-user-service.service';
import { CurrencyCodeModel } from 'src/app/models/currency-code-model';
import { CurrencyService } from '../../currency-service/currency.service';


declare var Stripe; // : stripe.StripeStatic;

@Component({
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class ShoppingCartComponent implements OnInit, AfterContentInit, OnDestroy {

  @ViewChild('cardElement', { static: false }) cardElement: ElementRef;
  // this doesn't work inside an *ngIf ... so I followed an example at https://stackoverflow.com/questions/39366981/viewchild-in-ngif
  // private cardElement: ElementRef;
  // @ViewChild('cardElement', { static: false }) set content(content: ElementRef) {
  //   console.log('this content setter is getting called !!! for card Element', content);
  //   this.cardElement = content;
  // }
  // this is Obsolete in Angular 8 with the "static" flag on @ViewChild ... but this has to be accessed after the
  // view and the tab group is fully initilized ... so put a setTimeout call in ngAfterViewInit() and then set up
  // the stripe elements form.


  public associatedOrderUid = '';
  public associatedOrderUids$: Observable<any[]>;
  private associatedOrderUidsSubscription: Subscription;
  public associatedOrderOne$: Observable<OrderModel>;
  private associatedOrderOneSubscription: Subscription;
  public associatedOrderOne: OrderModel;

  public orderLineItems: CartLineItemModel[] = [];

  public theBillingAddress$: Observable<StreetAddressModel>;
  public theShippingAddress$: Observable<StreetAddressModel>;
  public theBillingAddress: StreetAddressModel;
  public theShippingAddress: StreetAddressModel;

  public theCurrentUserUid = 'no user';

  private theUserDataSubscription: Subscription;

  // public currencyConversion$: Observable<CurrencyCodeModel>;
  private currencyConversionSubcription: Subscription;
  public currencyConversionRecord: CurrencyCodeModel;

  public loading = true;
  public currentOrderIsEmpty = false;
  public billLoading = true;
  public shipLoading = true;

  public promoStatus = 'nocode';  // this should move through 'nocode', 'entering', 'checking', 'applied'
  public promoMessage = '';

  private previousTab = 0;
  public selectedTab = 0;

  public showShipping: boolean;
  public cartRunningSubtotal = 0;
  public cartRunningDiscount = 0;
  public cartRunningTotal = 0;
  public totalNumberOfItemsInOrder = 0;

  public calcdOrderCost = 0;
  public calcdShippingCost = 0;
  public calcdTaxCost = 0;

  public editingPromo = false;
  public updatingBackend = false;

  private stripe; // : stripe.Stripe;
  public card;
  public cardErrors;
  public ccElementIsComplete = false;
  public ccIsEntered = false;



  // Forms for cart.
  public billingInfoForm = this.fb.group({
    emailAddress: ['', Validators.required],
    addressDetailsNestedForm: ''
  });

  public shippingInfoForm = this.fb.group({
    shipAddressDetailsNestedForm: ''
  });

  shipmethod = [
    { linetext: 'Pickup in Waterloo', type: 'pickup', cost: 0 },
    { linetext: 'Regular Shipping', type: 'regular', cost: 10 },
    { linetext: 'Express Shipping', type: 'express', cost: 40 },
  ];

  public creditCardInfoForm = this.fb.group({
    nameAsOnCard: ['', Validators.required]
  });

  public promoCodeForm = this.fb.group({
    promoCodeBox: ''
  });
  promoControl: AbstractControl;



  constructor(
    public authService: AuthService,
    public orderMgmtService: OrderManagementService,
    private currencyService: CurrencyService,
    public addressService: AddressMgmtService,
    private promoCodeUserService: PromoCodeUserService,
    public fb: FormBuilder,
    private router: Router,
    public gu: GlobalUtilitiesService
  ) { }

  async ngOnInit() {
    // Going to want to get this when a user logs in and show it in the header if there is a pending order.
    // get open orders for this user. Or display empty cart if none.



    // this.authService.user.subscribe(theUser => {
    //   console.log('!!! In the SHOPPING CART ngOnInit and the user subscription has fired !!!');
    //   console.log('The User Value is: ', theUser);
    // });

    this.theUserDataSubscription = this.orderMgmtService.isUserCurrentlyLoggedIn$.subscribe({
      next: async (isThereACurrentUser) => {
        console.log('!!! In the SHOPPING CART ngOnInit and the user subscription has fired from order mgmt service !!!');
        console.log('The User Value is: ', isThereACurrentUser);

        if (isThereACurrentUser) {
          console.log('!!!! There IS a user');

          this.theCurrentUserUid = this.authService.currentUserId();
          console.log('The current user UID is: ', this.theCurrentUserUid);

          // set the CurrentUserUid to the logged in user

          // Check for an existing cart in Local Storage ... if it is there then bring it in and make it the users active cart.
          // if the user already has an active cart then add it's line items to the active cart.

          // const localOpenOrderDoc = await this.checkForLocalOrderUid();
          // if (localOpenOrderDoc) {
          //   // check to see if there is an open order for the user !
          //   const userOpenOrderDocsList = await this.orderMgmtService.getJustTheUidForOpenOrdersForUser(theUser.uid).pipe(
          //     take(1)
          //   ).toPromise();

          //   let newOpenOrderDocUid: string;

          //   if (userOpenOrderDocsList.length > 0) {
          //     // already an open order for this user ... so add the items for the previous.
          //     const localOrderLineItems = await this.orderMgmtService.getLineItemsForOrder(localOpenOrderDoc).toPromise();
          //     // now delete the old order - or something like that.


          //   } else {
          //     // no open order for this user so just make the local order id into the open order for this user.
          //     newOpenOrderDocUid = localOpenOrderDoc;
          //     await this.orderMgmtService.updateOrderByUid(localOpenOrderDoc, {customerUserUID: theUser.uid});
          //   }
          //   // if there is not an existing subscription to the list then create it.  (code copied from below)
          //   if (!this.associatedOrderUidsSubscription) {
          //     this.associatedOrderUids$ = this.orderMgmtService.getJustTheUidForOpenOrdersForUser(theUser.uid).pipe(map(itemd => {
          //       console.log('Value Changes has returned an observable of the Pending Order UID (component)!!', itemd);
          //       return itemd;
          //     }));

          //     this.associatedOrderUidsSubscription = this.associatedOrderUids$.subscribe(returnedOrderList => {

          //       console.log('In the Cart - Subcribe to the UID of the order for the logged in user');

          //       if (returnedOrderList.length > 0) {

          //         // Only do the subscription the first time and if the result ever changes which it shouldn't unless you do something on another tab.
          //         if (this.loading) {
          //           console.log('In the Cart - Subcribe to the UID of the order for Loading is true so set up order observable.');
          //           this.setUpObservableForOpenOrder(returnedOrderList[0].uid);
          //         } else if (this.associatedOrderUid !== returnedOrderList[0].uid) {
          //           console.log('In the Cart - Subcribe to the UID of the order - the order uid has changed so rest tthe order observable.');
          //           // kill the existing subscription and then do a new one.
          //           if (this.associatedOrderOneSubscription) {
          //             this.associatedOrderOneSubscription.unsubscribe();
          //           }
          //           this.setUpObservableForOpenOrder(returnedOrderList[0].uid);
          //         }

          //       } else {
          //         console.log('THE LIST IS EMPTY ... DOES THIS GET CALLED ??');
          //         this.currentOrderIsEmpty = true;
          //       }
          //     });
          //   }

          // }
          // else there is not local storage order.

        } else {
          console.log('!!!! There is NOT a logged in user');

          console.log('In the shopping cart user subscription and the theCurrentUserUid is: ', this.theCurrentUserUid);
          // check to see if there was a user and if so then you got logged out ... so get out of here.
          if (this.theCurrentUserUid !== 'no user') {
            console.log('THIS IS A LOG OUT - PROBABLY need to get out of here');
            this.router.navigate(['/']);
          } else {
            // will set this to no user
            this.theCurrentUserUid = this.authService.currentUserId();
            console.log('The current user UID is: ', this.theCurrentUserUid);
          }
        }
      },
      error: (theError) => {
        console.log('An ERROR occurred getting the User Observable from the AuthService');
      }
    });
    // end of subscribe.



    // now subscribe to the currently open order.
    this.associatedOrderOneSubscription = this.orderMgmtService.currentOpenOrderUserOrNot$.subscribe(tempOrder => {
      // console.log('I am not ever seeing this am I !!');
      // console.log('what is Temp Order then ???');
      console.log(tempOrder);
      if (tempOrder) {
        // console.log('in the subscribe ... tempOrder is: ', tempOrder);
        this.associatedOrderOne = tempOrder;
        this.associatedOrderUid = this.associatedOrderOne.uid;

        this.orderMgmtService.getLineItemsForOrder(this.associatedOrderOne.uid).subscribe(returnedLineItems => {
          this.orderLineItems = returnedLineItems;

          let tempRunningTotal = 0;
          let tempNumberOfItems = 0;
          this.orderLineItems.forEach(element => {
            tempRunningTotal += (element.quantity * element.price);
            tempNumberOfItems += element.quantity;
          });
          this.cartRunningSubtotal = tempRunningTotal;
          if (this.associatedOrderOne.promoApplied === 'yes') {
            if (this.associatedOrderOne.promoIsPercentage) {
              this.cartRunningDiscount = this.cartRunningSubtotal * (this.associatedOrderOne.promoAmount / 100);
            } else {
              this.cartRunningDiscount = this.associatedOrderOne.promoAmount;
            }
          } else {
            this.cartRunningDiscount = 0;
          }
          this.cartRunningTotal = this.cartRunningSubtotal - this.cartRunningDiscount;

          this.totalNumberOfItemsInOrder = tempNumberOfItems;
        });

        // Get the Address Observables for this order.
        if (this.associatedOrderOne.billingAddressUid === this.associatedOrderOne.shippingAddressUid) {
          // console.log('setting show shipping to FALSE');
          this.showShipping = false;
        } else {
          // console.log('setting show shipping to TRUE');
          this.showShipping = true;
        }

        if (this.associatedOrderOne.billingAddressUid === 'new') {
          this.theBillingAddress = new StreetAddressModel();
          this.patchBillingAddressForm();
          this.billLoading = false;
        } else {
          this.theBillingAddress$ = this.addressService.getAddressObservable(this.associatedOrderOne.billingAddressUid);
          this.theBillingAddress$.subscribe(returnedBA => {
            this.theBillingAddress = returnedBA;
            this.patchBillingAddressForm();
            this.billLoading = false;

            if (this.gu.currencyCodeFromCountryCode(this.theBillingAddress.addressCountry) === 'USD') {
              if (this.currencyConversionSubcription) {
                this.currencyConversionSubcription.unsubscribe();
              }
              this.currencyConversionRecord = null;
            } else {
              if (this.currencyConversionSubcription) {
                this.currencyConversionSubcription.unsubscribe();
              }
              // This needs to be a subscription as the exhange rates are updated a few times a day and this will keep the latest in the cart.
              this.currencyConversionSubcription = this.currencyService.getCurrencyDetailsAndCurrentRate(this.gu.currencyCodeFromCountryCode(this.theBillingAddress.addressCountry))
              .subscribe(theCurrencyDetails => {
                this.currencyConversionRecord = theCurrencyDetails;
              });
            }
          });
        }

        if (this.associatedOrderOne.shippingAddressUid === 'new') {
          this.theShippingAddress = new StreetAddressModel();
          this.patchShippingAddressForm();
          this.shipLoading = false;
        } else {
          this.theShippingAddress$ = this.addressService.getAddressObservable(this.associatedOrderOne.shippingAddressUid);
          this.theShippingAddress$.subscribe(returnedSA => {
            this.theShippingAddress = returnedSA;
            this.patchShippingAddressForm();
            this.shipLoading = false;
          });
        }

        this.patchBillingFormEmail();
        if (this.associatedOrderOne.promoApplied === 'no') {
          this.promoStatus = 'nocode';
        } else {
          this.promoStatus = 'applied';
        }

        this.loading = false;
        this.currentOrderIsEmpty = false;


      } else {
        // There is no order so mark the cart as empty.
        console.log('There is no cart so the Cart is Empty ??');
        this.loading = true;
        this.currentOrderIsEmpty = true;
      }
    });




    // if (this.theCurrentUserUid === 'no user') {

    //   // No user is logged in ... so get check local storage for an order UID and if found then use it if not then create it.
    //   const openOrderDocUid = await this.checkForLocalOrderUid();

    //   // if not found then ... create one
    //   if (openOrderDocUid) {
    //     console.log('BROWSER HAS OPEN ORDER - Setup observable to it !!');
    //     this.setUpObservableForOpenOrder(openOrderDocUid);
    //   } else {
    //     console.log('NO OPEN ORDER ON THIS BROWSER EMPTY');
    //     this.currentOrderIsEmpty = true;
    //   }
    // } else {

    //   // if there is not an existing subscription to the list then create it.  (code copied from above)
    //   if (!this.associatedOrderUidsSubscription) {
    //     this.associatedOrderUids$ = this.orderMgmtService.getJustTheUidForOpenOrdersForUser(this.theCurrentUserUid).pipe(map(itemd => {
    //       console.log('Value Changes has returned an observable of the Pending Order UID (component)!!', itemd);
    //       return itemd;
    //     }));

    //     this.associatedOrderUidsSubscription = this.associatedOrderUids$.subscribe(returnedOrderList => {

    //       console.log('In the Cart - Subcribe to the UID of the order for the logged in user');

    //       if (returnedOrderList.length > 0) {

    //         // Only do the subscription the first time and if the result ever changes which it shouldn't unless you do something on another tab.
    //         if (this.loading) {
    //           console.log('In the Cart - Subcribe to the UID of the order for Loading is true so set up order observable.');
    //           this.setUpObservableForOpenOrder(returnedOrderList[0].uid);
    //         } else if (this.associatedOrderUid !== returnedOrderList[0].uid) {
    //           console.log('In the Cart - Subcribe to the UID of the order - the order uid has changed so rest tthe order observable.');
    //           // kill the existing subscription and then do a new one.
    //           if (this.associatedOrderOneSubscription) {
    //             this.associatedOrderOneSubscription.unsubscribe();
    //           }
    //           this.setUpObservableForOpenOrder(returnedOrderList[0].uid);
    //         }

    //       } else {
    //         console.log('THE LIST IS EMPTY ... DOES THIS GET CALLED ??');
    //         this.currentOrderIsEmpty = true;
    //       }
    //     });
    //   }
    // }

  }


  ngAfterContentInit() {

    console.log('Now in after content init');
    console.log('try doing the setup after a delay');
    setTimeout(() => {
      this.setupStripeElements();
    }, 500);

    // Add capitalizer for promo codes
    this.promoControl = this.promoCodeForm.get('promoCodeBox');
    this.promoControl.valueChanges.subscribe(event => {
      console.log('This is the subscribe to Promo Code changes and this is the event: ', event);
      this.promoControl.patchValue((this.promoControl.value).toUpperCase().replace(/[^\w\s]|_/g, '').replace(/\s/g, ''), { emitEvent: false });
    });
  }

  ngOnDestroy() {
    console.log('When does the cart component get Destroyed !!!!!  NOW ');
    if (this.associatedOrderOneSubscription) {
      this.associatedOrderOneSubscription.unsubscribe();
    }
    if (this.theUserDataSubscription) {
      this.theUserDataSubscription.unsubscribe();
    }
    // if (this.associatedOrderUidsSubscription) {
    //   this.associatedOrderUidsSubscription.unsubscribe();
    // }

  }

  async tabWasChanged(theEvent: any) {
    console.log('the tab was changed and the new tab is: ', theEvent);

    if (this.previousTab === 1 && this.billingInfoForm.valid && this.billingInfoForm.dirty) {
      console.log('Tab change away from 1 and the form is dirty and valid - so saving it');
      await this.saveBillingFormToBackend('navigate');
    }

    if (this.previousTab === 2 && this.showShipping && this.shippingInfoForm.valid && this.shippingInfoForm.dirty) {
      console.log('Tab change away from 2 and the form is dirty and valid and show shipping - so saving it');
      await this.saveShippingFormToBackend('navigate');
    }

    this.selectedTab = theEvent;
    this.previousTab = theEvent;

    if ((theEvent === 4 && this.showShipping) || (theEvent === 3 && !this.showShipping)) {
      // is the Credit card page.
      this.ccElementIsComplete = false;
    }

    if ((theEvent === 5 && this.showShipping) || (theEvent === 4 && !this.showShipping)) {
      // is the Confirmation page.
      this.calculateFinalCosts();
    }
  }

  moveToNextTab(fromTab: string = '') {
    console.log('move to next tab if there is one, from tab is: ', fromTab);
    if (fromTab === '') {
      this.selectedTab++;
    } else if (fromTab === 'contents') {
      this.selectedTab = 1;
      this.editingPromo = false;
    } else if (fromTab === 'billaddress') {
      this.selectedTab = 2;
    } else if (fromTab === 'shipaddress') {
      this.selectedTab = 3;
    } else if (fromTab === 'shipmethod') {
      this.selectedTab = (this.showShipping ? 4 : 3);
    } else if (fromTab === 'payment') {
      this.selectedTab = (this.showShipping ? 5 : 4);
    }
  }

  moveToPreviousTab(fromTab: string = '') {
    console.log('move to previous tab if there is one, from tab is: ', fromTab);
    if (fromTab === '') {
      if (this.selectedTab > 0) {
        this.selectedTab--;
      }
    } else if (fromTab === 'billaddress') {
      console.log('in previous of bill address');
      this.selectedTab = 0;
      console.log('in previous of bill address-after setting selected Tab');
    } else if (fromTab === 'shipaddress') {
      this.selectedTab = 1;
    } else if (fromTab === 'shipmethod') {
      this.selectedTab = (this.showShipping ? 2 : 1);
    } else if (fromTab === 'payment') {
      this.selectedTab = (this.showShipping ? 3 : 2);
    } else if (fromTab === 'confirm') {
      this.selectedTab = (this.showShipping ? 4 : 3);
    }
  }











  // CART CONTENTS ITEMS

  updateQuantityOfLineItem(direction: string, theLineItem: any) {
    console.log('Got the update quantity event ... direction is ' + direction + ', and the UID is: ', theLineItem);

    const tempJSON: any = {};

    if (direction === 'down') {

      tempJSON.quantity = theLineItem.quantity - 1;

    } else {

      tempJSON.quantity = theLineItem.quantity + 1;
    }
    tempJSON.lineItemSubtotal = tempJSON.quantity * theLineItem.price;

    // push it up to the db.
    this.orderMgmtService.updateLineItemInOrder(this.associatedOrderUid, theLineItem.uid, tempJSON);
  }


  removeLineItem(theUid: string) {
    console.log('Got the remove item event ... and the UID is: ' + theUid);

    this.orderMgmtService.deleteLineItemInOrder(this.associatedOrderUid, theUid);
  }


  private getNumberOfItemsInOrder(): number {
    let theResult = 0;
    this.orderLineItems.forEach(tempOrderLineItem => {
      theResult += tempOrderLineItem.quantity;
    });

    return theResult;
  }


  public requestToEnterPromoCode(theType: string = 'none') {
    if (theType === 'none') {
      this.promoCodeForm.patchValue({ promoCodeBox: '' });
    } else {
      this.promoCodeForm.patchValue({ promoCodeBox: this.associatedOrderOne.promoCode });
    }
    this.promoMessage = '';
    this.promoControl.setErrors(null);
    this.promoStatus = 'entering';
  }

  // public validateAndApplyPromoCode() {
  //   this.promoStatus = 'checking';
  // }

  public async onPromoCodeFormSubmit() {
    this.promoStatus = 'checking';

    if (this.promoControl.value === '') {
      console.log('PROMO CODE EMPTY - Reset');
      if (this.associatedOrderOne.promoApplied === 'yes') {
        // remove the promo code first
        await this.promoCodeUserService.removePromoCodeFromOrderUid(this.associatedOrderOne.uid);
      }
      this.promoStatus = 'nocode';

    } else {

      const validationResult = await this.promoCodeUserService.checkPromoCodeIsValid(this.promoControl.value);
      console.log('Validate Promo Code = code validation is: ', validationResult.result);

      if (validationResult.result === 'valid') {
        // do setting
        if (this.associatedOrderOne.promoApplied === 'no') {
          this.promoCodeUserService.addPromoCodeToOrderUid(this.promoControl.value, this.associatedOrderOne.uid);
        } else {
          this.promoCodeUserService.replaceExistingPromoCodeFromOrderUid(this.promoControl.value, this.associatedOrderOne.uid);
        }

        this.promoStatus = 'applied';

      } else {
        this.promoMessage = 'Promo code entered ' + validationResult.result;
        this.promoControl.setErrors({ 'badcode': true });

        this.promoStatus = 'entering';
      }
    }
  }

  public onPromoCodeFormCancel() {
    if (this.associatedOrderOne.promoApplied === 'no') {
      this.promoStatus = 'nocode';
    } else {
      this.promoStatus = 'applied';
    }

  }



















  // ***** BILLING FORM ITEMS

  private patchBillingFormEmail() {
    this.billingInfoForm.get('emailAddress').patchValue(this.associatedOrderOne.customerEmail);
  }

  private patchBillingAddressForm() {

    this.billingInfoForm.patchValue({
      addressDetailsNestedForm: {
        addressFirstName: this.theBillingAddress.addressFirstName,
        addressLastName: this.theBillingAddress.addressLastName,
        addressFullName: '',
        addressCompanyName: this.theBillingAddress.addressCompanyName,

        addressLine1: this.theBillingAddress.addressLine1,
        addressLine2: this.theBillingAddress.addressLine2,
        addressLine3: this.theBillingAddress.addressLine3,
        addressCity: this.theBillingAddress.addressCity,
        addressStateOrProvince: this.theBillingAddress.addressStateOrProvince,
        addressCountyOrRegion: this.theBillingAddress.addressCountyOrRegion,
        addressPostalCode: this.theBillingAddress.addressPostalCode,
        addressPostalCodeSuffix: this.theBillingAddress.addressPostalCodeSuffix,
        addressCountry: this.theBillingAddress.addressCountry,
        addressAssociatedPhone: this.theBillingAddress.addressAssociatedPhone,

        latitude: this.theBillingAddress.latitude,
        longitude: this.theBillingAddress.longitude,
      }
    });

    this.creditCardInfoForm.get('nameAsOnCard').patchValue(this.theBillingAddress.addressFirstName + ' ' + this.theBillingAddress.addressLastName);
  }

  private populateFromBillingForm() {
    this.associatedOrderOne.customerEmail = this.billingInfoForm.get('emailAddress').value || '';
    this.theBillingAddress.addressFirstName = this.billingInfoForm.get('addressDetailsNestedForm').value.addressFirstName || '';
    this.theBillingAddress.addressLastName = this.billingInfoForm.get('addressDetailsNestedForm').value.addressLastName || '';
    this.theBillingAddress.addressCompanyName = this.billingInfoForm.get('addressDetailsNestedForm').value.addressCompanyName || '';

    this.theBillingAddress.addressLine1 = this.billingInfoForm.get('addressDetailsNestedForm').value.addressLine1 || '';
    this.theBillingAddress.addressLine2 = this.billingInfoForm.get('addressDetailsNestedForm').value.addressLine2 || '';
    this.theBillingAddress.addressLine3 = this.billingInfoForm.get('addressDetailsNestedForm').value.addressLine3 || '';
    this.theBillingAddress.addressCity = this.billingInfoForm.get('addressDetailsNestedForm').value.addressCity || '';
    this.theBillingAddress.addressStateOrProvince = this.billingInfoForm.get('addressDetailsNestedForm').value.addressStateOrProvince || '';
    this.theBillingAddress.addressPostalCode = this.billingInfoForm.get('addressDetailsNestedForm').value.addressPostalCode || '';
    this.theBillingAddress.addressCountry = this.billingInfoForm.get('addressDetailsNestedForm').value.addressCountry || '';
    this.theBillingAddress.addressAssociatedPhone = this.billingInfoForm.get('addressDetailsNestedForm').value.addressAssociatedPhone || '';
  }

  private async saveBillingFormToBackend(fromSubmitOrNavigateAway: string = 'submit') {

    this.updatingBackend = true;

    console.log('Address form value: ', this.billingInfoForm.get('addressDetailsNestedForm').value);

    this.populateFromBillingForm();

    console.log('the billing address ... Post retrieval: ', this.theBillingAddress);

    // create or update the billing address for the Order.
    if (this.associatedOrderOne.billingAddressUid === 'new') {
      const theReturnedUid = await this.addressService.createNewAddressInDB(this.theBillingAddress);
      this.associatedOrderOne.billingAddressUid = theReturnedUid;
      if (!this.showShipping) {
        this.associatedOrderOne.shippingAddressUid = theReturnedUid;
      }
    } else {
      await this.addressService.updateWholeAddressInDB(this.theBillingAddress);

      if (!this.showShipping) {
        this.associatedOrderOne.shippingAddressUid = this.associatedOrderOne.billingAddressUid;
      }
    }

    // updating the order should update the and cause it to re-set the observable
    await this.orderMgmtService.updateWholeOrder(this.associatedOrderOne);

    console.log('After the update of the billing address ... should see some Observable activity');

    // this doesnt work.
    this.billingInfoForm.get('addressDetailsNestedForm').markAsPristine();
    // this one works
    this.billingInfoForm.markAsPristine();

    if (fromSubmitOrNavigateAway === 'submit') {
      this.moveToNextTab('billaddress');
    }
    this.updatingBackend = false;
  }

  public async onBillingFormSubmit() {
    console.log('Form Values', this.billingInfoForm.value);

    console.log('the billing address ... pre retrieval: ', this.theBillingAddress);

    if (this.billingInfoForm.dirty) {
      await this.saveBillingFormToBackend('submit');
    } else {
      // just move to the next tab.
      this.moveToNextTab('billaddress');
    }

  }

  public checkInvalidControls() {
    console.log(this.findInvalidControls());
  }

  public findInvalidControls() {
    // console.log('inside the find invalid controls');
    const invalid = [];
    const controls = this.billingInfoForm.controls;
    // tslint:disable-next-line:forin
    for (const name in controls) {
      // console.log('the control name is:', name);
      if (controls[name].invalid) {
        // console.log('the control is invalid');
        invalid.push(name);
      }
    }
    return invalid;
  }

  public shippingToggle() {
    if (this.showShipping) {
      console.log('toggle shipping off');
      // if there is a shippingForm then I should delete it on the back end. ****
      // will get updated when billing address is saved.
    } else {
      console.log('toggle shipping on');
      this.associatedOrderOne.shippingAddressUid = 'new';
    }
    this.showShipping = !this.showShipping;
    this.billingInfoForm.markAsDirty();
  }





















  //  SHIPPING ADDRESS FORM ITEMS
  private patchShippingAddressForm() {

    this.shippingInfoForm.patchValue({
      shipAddressDetailsNestedForm: {
        addressFirstName: this.theShippingAddress.addressFirstName,
        addressLastName: this.theShippingAddress.addressLastName,
        addressFullName: '',
        addressCompanyName: this.theShippingAddress.addressCompanyName,

        addressLine1: this.theShippingAddress.addressLine1,
        addressLine2: this.theShippingAddress.addressLine2,
        addressLine3: this.theShippingAddress.addressLine3,
        addressCity: this.theShippingAddress.addressCity,
        addressStateOrProvince: this.theShippingAddress.addressStateOrProvince,
        addressCountyOrRegion: this.theShippingAddress.addressCountyOrRegion,
        addressPostalCode: this.theShippingAddress.addressPostalCode,
        addressPostalCodeSuffix: this.theShippingAddress.addressPostalCodeSuffix,
        addressCountry: this.theShippingAddress.addressCountry,
        addressAssociatedPhone: this.theShippingAddress.addressAssociatedPhone,

        latitude: this.theShippingAddress.latitude,
        longitude: this.theShippingAddress.longitude,
      }
    });
  }

  private populateFromShippingForm() {
    this.theShippingAddress.addressFirstName = this.shippingInfoForm.get('shipAddressDetailsNestedForm').value.addressFirstName || '';
    this.theShippingAddress.addressLastName = this.shippingInfoForm.get('shipAddressDetailsNestedForm').value.addressLastName || '';
    this.theShippingAddress.addressCompanyName = this.shippingInfoForm.get('shipAddressDetailsNestedForm').value.addressCompanyName || '';

    this.theShippingAddress.addressLine1 = this.shippingInfoForm.get('shipAddressDetailsNestedForm').value.addressLine1 || '';
    this.theShippingAddress.addressLine2 = this.shippingInfoForm.get('shipAddressDetailsNestedForm').value.addressLine2 || '';
    this.theShippingAddress.addressLine3 = this.shippingInfoForm.get('shipAddressDetailsNestedForm').value.addressLine3 || '';
    this.theShippingAddress.addressCity = this.shippingInfoForm.get('shipAddressDetailsNestedForm').value.addressCity || '';
    this.theShippingAddress.addressStateOrProvince = this.shippingInfoForm.get('shipAddressDetailsNestedForm').value.addressStateOrProvince || '';
    this.theShippingAddress.addressPostalCode = this.shippingInfoForm.get('shipAddressDetailsNestedForm').value.addressPostalCode || '';
    this.theShippingAddress.addressCountry = this.shippingInfoForm.get('shipAddressDetailsNestedForm').value.addressCountry || '';
    this.theShippingAddress.addressAssociatedPhone = this.shippingInfoForm.get('shipAddressDetailsNestedForm').value.addressAssociatedPhone || '';
  }


  private async saveShippingFormToBackend(fromSubmitOrNavigateAway: string = 'submit') {

    this.updatingBackend = true;
    console.log('Address form value: ', this.shippingInfoForm.get('shipAddressDetailsNestedForm').value);
    this.populateFromShippingForm();
    console.log('the shipping address ... Post retrieval: ', this.theShippingAddress);

    // create or update the billing address for the Order.
    if (this.associatedOrderOne.shippingAddressUid === 'new') {
      const theReturnedShippingUid = await this.addressService.createNewAddressInDB(this.theShippingAddress);
      this.associatedOrderOne.shippingAddressUid = theReturnedShippingUid;

    } else {
      await this.addressService.updateWholeAddressInDB(this.theShippingAddress);

    }

    // updating the order should update the and cause it to re-set the observable
    await this.orderMgmtService.updateWholeOrder(this.associatedOrderOne);
    console.log('After the update of the shipping address ... should see some Observable activity');

    // this doesnt work.
    this.shippingInfoForm.get('shipAddressDetailsNestedForm').markAsPristine();
    // this one works
    this.shippingInfoForm.markAsPristine();

    if (fromSubmitOrNavigateAway === 'submit') {
      this.moveToNextTab('shipaddress');
    }
    this.updatingBackend = false;
  }

  public async onShippingFormSubmit() {
    console.log('Form Values', this.shippingInfoForm.value);
    console.log('the shipping address ... pre retrieval: ', this.theShippingAddress);

    if (this.shippingInfoForm.dirty) {
      await this.saveShippingFormToBackend('submit');
    } else {
      // just move to the next tab.
      this.moveToNextTab('shipaddress');
    }
  }









  //  SHIPPING ADDRESS FORM ITEMS

  public async shippingMethodChange($event: MatRadioChange, methodType: string, methodCost: number) {
    console.log('Shipping readio button changed: ');
    console.log($event);
    console.log($event.source.name, $event.value, methodType, methodCost);

    this.updatingBackend = true;

    const tempJSON: any = {};
    tempJSON.shippingMethod = methodType;

    await this.orderMgmtService.updateOrderByUid(this.associatedOrderOne.uid, tempJSON);

    this.updatingBackend = false;

  }

  private getCostOfShippingMethod(theMethod: string): number {
    console.log('get cost subroutine is being called !!!!');
    const theResultObj = this.shipmethod.find(obj => {
      return obj.type === theMethod;
    });

    return theResultObj.cost;

  }















  // SHIPPING METHOD FROM ITEMS


















  // CREDIT CARD FROM ITEMS
  private setupStripeElements() {

    console.log('Now in setup Stripe Elements');
    // console.log(this.cardElement);
    // console.log(this.cardElement.nativeElement);

    if (this.cardElement) {
      this.stripe = Stripe(environment.stripePublicKey);
      const elements = this.stripe.elements();

      this.card = elements.create('card');

      this.card.mount(this.cardElement.nativeElement);

      if (this.associatedOrderOne.stripePrebillingObjectRef !== '') {
        this.ccIsEntered = true;
      } else {
        this.ccIsEntered = false;
      }

      // this.card.addEventListener('change', ({ error }) => {
      this.card.addEventListener('change', event => {
        // console.log('change listener fired on credit card');
        console.log('the whole event is: ', event);
        this.cardErrors = event.error && event.error.message;
        this.card.update({ value: { postalCode: this.theBillingAddress.addressPostalCode } });
        this.ccElementIsComplete = event.complete;
      });

      // this.card.addEventListener('focus', focusEvent => {
      //   console.log('The Focus event fired !! the full Event is: ', focusEvent);
      //   this.card.update({value: {postalCode: this.theBillingAddress.addressPostalCode}});
      // });

      console.log('the elements are: ', elements);
      console.log('the card is: ', this.card);
    } else {
      console.log('Form still not set up ...');

      // setTimeout(() => {
      //   this.setupStripeElements();
      // }, 1000);
    }
  }


  public async handleCreditCardForm(formEvent: any) {
    console.log('After the Credit card Form submit and the form event is: ', formEvent);
    // console.log('The card is: ', this.card);

    // const { source, error } = await this.stripe.createSource(this.card);
    // console.log('the source is: ', source);
    // console.log('the error is: ', error);

    // const { token, error2 } = await this.stripe.createToken(this.card, {
    //   name: this.creditCardInfoForm.get('nameAsOnCard').value
    // });
    // console.log('the source is: ', token);
    // console.log('the error is: ', error2);
    // this.associatedOrderOne.stripePrebillingObjectRef = token.id;
    // this.associatedOrderOne.stripePrebillingCardBrand = token.card.brand;
    // this.associatedOrderOne.stripePrebillingCardLast4 = token.card.last4;
    // this.ccIsEntered = true;

    this.updatingBackend = true;

    if (!this.ccIsEntered) {

      const paymentData: any = {
        billing_details: {
          address: {
            line1: this.theBillingAddress.addressLine1,
            line2: this.theBillingAddress.addressLine2,
            city: this.theBillingAddress.addressCity,
            state: this.theBillingAddress.addressStateOrProvince,
            postal_code: this.theBillingAddress.addressPostalCode,
            country: this.theBillingAddress.addressCountry
          },
          name: this.creditCardInfoForm.get('nameAsOnCard').value,
          email: this.associatedOrderOne.customerEmail,
          phone: this.theBillingAddress.addressAssociatedPhone,
        },
        metadata: {
          orderUid: this.associatedOrderOne.uid
        }
      };

      // console.log('The payment data before removal is: ', paymentData);

      // console.log('The payment data after removal is: ', this.gu.removeEmpty(paymentData));

      // use the payment intents instead of charge tokens on stripe.
      const pmResult = await this.stripe.createPaymentMethod('card', this.card, this.gu.removeEmpty(paymentData));

      // console.log('Returnd from creating the payment method ... the results are: ');
      // console.log(pmResult);

      if (pmResult.error) {
        console.log('An error occurred creating the payment method and it is: ');
        console.log(pmResult.error);
      } else {
        console.log('successfull created payment method and it is: ');
        console.log(pmResult.paymentMethod);
        this.associatedOrderOne.stripePrebillingObjectRef = pmResult.paymentMethod.id;
        this.associatedOrderOne.stripePrebillingCardBrand = pmResult.paymentMethod.card.brand;
        this.associatedOrderOne.stripePrebillingCardLast4 = pmResult.paymentMethod.card.last4;
        this.associatedOrderOne.stripePrebillingCardExpMonth = pmResult.paymentMethod.card.exp_month;
        this.associatedOrderOne.stripePrebillingCardExpYear = pmResult.paymentMethod.card.exp_year;
        this.associatedOrderOne.nameOnCard = pmResult.paymentMethod.billing_details.name;

        await this.orderMgmtService.updateWholeOrder(this.associatedOrderOne);
        console.log('After the update of the credit card info ... should see some Observable activity');

        this.ccIsEntered = true;
      }
    }

    this.updatingBackend = false;

    if (this.ccIsEntered) {

      this.moveToNextTab('payment');
    }

  }


  public async resetCC() {
    this.associatedOrderOne.nameOnCard = '';
    this.associatedOrderOne.stripePrebillingObjectRef = '';
    this.associatedOrderOne.stripePrebillingCardBrand = '';
    this.associatedOrderOne.stripePrebillingCardLast4 = '';
    this.ccIsEntered = false;
    this.card.clear();
  }























  // CONFIRMATION PAGE FORM ITEMS
  private calculateTaxAmount(): number {

    if (this.theBillingAddress.addressCountry === 'CA') {
      // Tax is due.
      const taxEntry = this.gu.tax_rates.find(obj => {
        return obj.jurisdiction === this.theBillingAddress.addressStateOrProvince;
      });

      this.associatedOrderOne.salesOrVAT1Total = (taxEntry.tax1rate / 100) * this.cartRunningTotal;
      this.associatedOrderOne.salesOrVAT1Jurisdiction = taxEntry.jurisdiction;
      this.associatedOrderOne.salesOrVAT1TaxName = taxEntry.tax1name + ' ' + taxEntry.tax1rate + '%';

      this.associatedOrderOne.salesOrVAT2Total = (taxEntry.tax2rate / 100) * this.cartRunningTotal;
      this.associatedOrderOne.salesOrVAT2Jurisdiction = taxEntry.jurisdiction;
      this.associatedOrderOne.salesOrVAT2TaxName = taxEntry.tax2name + ' ' + taxEntry.tax2rate + '%';

    } else {
      // no tax is due.
      this.associatedOrderOne.salesOrVAT1Total = 0;
      this.associatedOrderOne.salesOrVAT1Jurisdiction = '';
      this.associatedOrderOne.salesOrVAT1TaxName = 'N/A';

      this.associatedOrderOne.salesOrVAT2Total = 0;
      this.associatedOrderOne.salesOrVAT2Jurisdiction = '';
      this.associatedOrderOne.salesOrVAT2TaxName = '';

      return 0;
    }

  }

  private calculateFinalCosts() {

    this.calculateTaxAmount();

    this.calcdShippingCost = this.totalNumberOfItemsInOrder * this.getCostOfShippingMethod(this.associatedOrderOne.shippingMethod);

    this.calcdOrderCost = this.cartRunningSubtotal - this.cartRunningDiscount + this.calcdShippingCost + this.associatedOrderOne.salesOrVAT1Total + this.associatedOrderOne.salesOrVAT2Total;

    if (this.theBillingAddress.addressCountry !== 'US') {
      // what is the currency.
    }

  }

  public async placeOrder() {
    console.log('Place the order now on the backend');
    this.updatingBackend = true;

    this.associatedOrderOne.orderSubtotal = this.cartRunningSubtotal;
    this.associatedOrderOne.promoTotalDiscount = this.cartRunningDiscount;
    this.associatedOrderOne.orderTotalCharge = this.calcdOrderCost;
    this.associatedOrderOne.shippingCharge = this.calcdShippingCost;

    this.associatedOrderOne.customerName = this.theBillingAddress.addressFirstName + ' ' + this.theBillingAddress.addressLastName;
    this.associatedOrderOne.timestampOrderEntered = new Date();

    if (this.authService.isAuthenticated()) {
      this.associatedOrderOne.customerUserUID = this.authService.currentUserId();
      this.associatedOrderOne.orderPrivacy = 'private';
    }
    // else - just leave it as is set to 'no user'


    // call the service to call the backend to process the order.
    console.log('Placing order ... BEFORE Calling the service !!');
    const theChargeResult = await this.orderMgmtService.updateOrderAndPlace(this.associatedOrderOne);

    console.log('Placing order ... returned from the service !!');

    this.updatingBackend = false;

    // If there is an order Id in local storage then remove it.
    localStorage.removeItem('localStoredOrderUid');

    // go to the order invoice view now.
    this.router.navigate(['/orders/' + theChargeResult.returned_order_id]);

    console.log('returned from processing the charge and the result is: ', theChargeResult);

  }








}
