// Put shared types for dropdown menus and UI labels etc. in here to be used through the whole app
// Could be used for Localization or Label customization as needed
import { Injectable } from '@angular/core';


@Injectable()
export class GlobalUtilitiesService {

    states = [
        { name: 'Alabama', abbreviation: 'AL' },
        { name: 'Alaska', abbreviation: 'AK' },
        { name: 'American Samoa', abbreviation: 'AS' },
        { name: 'Arizona', abbreviation: 'AZ' },
        { name: 'Arkansas', abbreviation: 'AR' },
        { name: 'California', abbreviation: 'CA' },
        { name: 'Colorado', abbreviation: 'CO' },
        { name: 'Connecticut', abbreviation: 'CT' },
        { name: 'Delaware', abbreviation: 'DE' },
        { name: 'District Of Columbia', abbreviation: 'DC' },
        { name: 'Federated States Of Micronesia', abbreviation: 'FM' },
        { name: 'Florida', abbreviation: 'FL' },
        { name: 'Georgia', abbreviation: 'GA' },
        { name: 'Guam', abbreviation: 'GU' },
        { name: 'Hawaii', abbreviation: 'HI' },
        { name: 'Idaho', abbreviation: 'ID' },
        { name: 'Illinois', abbreviation: 'IL' },
        { name: 'Indiana', abbreviation: 'IN' },
        { name: 'Iowa', abbreviation: 'IA' },
        { name: 'Kansas', abbreviation: 'KS' },
        { name: 'Kentucky', abbreviation: 'KY' },
        { name: 'Louisiana', abbreviation: 'LA' },
        { name: 'Maine', abbreviation: 'ME' },
        { name: 'Marshall Islands', abbreviation: 'MH' },
        { name: 'Maryland', abbreviation: 'MD' },
        { name: 'Massachusetts', abbreviation: 'MA' },
        { name: 'Michigan', abbreviation: 'MI' },
        { name: 'Minnesota', abbreviation: 'MN' },
        { name: 'Mississippi', abbreviation: 'MS' },
        { name: 'Missouri', abbreviation: 'MO' },
        { name: 'Montana', abbreviation: 'MT' },
        { name: 'Nebraska', abbreviation: 'NE' },
        { name: 'Nevada', abbreviation: 'NV' },
        { name: 'New Hampshire', abbreviation: 'NH' },
        { name: 'New Jersey', abbreviation: 'NJ' },
        { name: 'New Mexico', abbreviation: 'NM' },
        { name: 'New York', abbreviation: 'NY' },
        { name: 'North Carolina', abbreviation: 'NC' },
        { name: 'North Dakota', abbreviation: 'ND' },
        { name: 'Northern Mariana Islands', abbreviation: 'MP' },
        { name: 'Ohio', abbreviation: 'OH' },
        { name: 'Oklahoma', abbreviation: 'OK' },
        { name: 'Oregon', abbreviation: 'OR' },
        { name: 'Palau', abbreviation: 'PW' },
        { name: 'Pennsylvania', abbreviation: 'PA' },
        { name: 'Puerto Rico', abbreviation: 'PR' },
        { name: 'Rhode Island', abbreviation: 'RI' },
        { name: 'South Carolina', abbreviation: 'SC' },
        { name: 'South Dakota', abbreviation: 'SD' },
        { name: 'Tennessee', abbreviation: 'TN' },
        { name: 'Texas', abbreviation: 'TX' },
        { name: 'Utah', abbreviation: 'UT' },
        { name: 'Vermont', abbreviation: 'VT' },
        { name: 'Virgin Islands', abbreviation: 'VI' },
        { name: 'Virginia', abbreviation: 'VA' },
        { name: 'Washington', abbreviation: 'WA' },
        { name: 'West Virginia', abbreviation: 'WV' },
        { name: 'Wisconsin', abbreviation: 'WI' },
        { name: 'Wyoming', abbreviation: 'WY' }
    ];

    can_provinces = [
        { name: 'Alberta', abbreviation: 'AB' },
        { name: 'British Columbia', abbreviation: 'BC' },
        { name: 'Manitoba', abbreviation: 'MB' },
        { name: 'New Brunswick', abbreviation: 'NB' },
        { name: 'Newfoundland & Labrador', abbreviation: 'NL' },
        { name: 'Nova Scotia', abbreviation: 'NS' },
        { name: 'Northwest Territories', abbreviation: 'NT' },
        { name: 'Nunavut', abbreviation: 'NU' },
        { name: 'Ontario', abbreviation: 'ON' },
        { name: 'Prince Edward Island', abbreviation: 'PE' },
        { name: 'Quebec', abbreviation: 'QC' },
        { name: 'Saskatchewan', abbreviation: 'SK' },
        { name: 'Yukon', abbreviation: 'YT' }
    ];

    countries = [
        { name: 'United States', abbreviation: 'US' },
        { name: 'Canada', abbreviation: 'CA' }
    ];

    allcountries = [
        { abbreviation: 'AF', name: 'Afghanistan', currency: 'AFN' },
        { abbreviation: 'AL', name: 'Albania', currency: 'ALL' },
        { abbreviation: 'DZ', name: 'Algeria', currency: 'DZD' },
        { abbreviation: 'AS', name: 'American Samoa', currency: 'USD' },
        { abbreviation: 'AD', name: 'Andorra', currency: 'EUR' },
        { abbreviation: 'AO', name: 'Angola', currency: 'AOA' },
        { abbreviation: 'AI', name: 'Anguilla', currency: 'XCD' },
        { abbreviation: 'AQ', name: 'Antarctica', currency: 'USD' },
        { abbreviation: 'AG', name: 'Antigua and Barbuda', currency: 'XCD' },
        { abbreviation: 'AR', name: 'Argentina', currency: 'ARS' },
        { abbreviation: 'AM', name: 'Armenia', currency: 'AMD' },
        { abbreviation: 'AW', name: 'Aruba', currency: 'AWG' },
        { abbreviation: 'AU', name: 'Australia', currency: 'AUD' },
        { abbreviation: 'AT', name: 'Austria', currency: 'EUR' },
        { abbreviation: 'AZ', name: 'Azerbaijan', currency: 'AZN' },
        { abbreviation: 'BS', name: 'Bahamas', currency: 'BSD' },
        { abbreviation: 'BH', name: 'Bahrain', currency: 'BHD' },
        { abbreviation: 'BD', name: 'Bangladesh', currency: 'BDT' },
        { abbreviation: 'BB', name: 'Barbados', currency: 'BBD' },
        { abbreviation: 'BY', name: 'Belarus', currency: 'BYN' },
        { abbreviation: 'BE', name: 'Belgium', currency: 'EUR' },
        { abbreviation: 'BZ', name: 'Belize', currency: 'BZD' },
        { abbreviation: 'BJ', name: 'Benin', currency: 'XOF' },
        { abbreviation: 'BM', name: 'Bermuda', currency: 'BMD' },
        { abbreviation: 'BT', name: 'Bhutan', currency: 'BTN' },
        { abbreviation: 'BO', name: 'Bolivia, Plurinational State of', currency: 'BOB' },
        { abbreviation: 'BQ', name: 'Bonaire, Sint Eustatius and Saba', currency: 'USD' },
        { abbreviation: 'BA', name: 'Bosnia and Herzegovina', currency: 'BAM' },
        { abbreviation: 'BW', name: 'Botswana', currency: 'BWP' },
        { abbreviation: 'BV', name: 'Bouvet Island', currency: 'NOK' },
        { abbreviation: 'BR', name: 'Brazil', currency: 'BRL' },
        { abbreviation: 'IO', name: 'British Indian Ocean Territory', currency: 'USD' },
        { abbreviation: 'BN', name: 'Brunei Darussalam', currency: 'BND' },
        { abbreviation: 'BG', name: 'Bulgaria', currency: 'BGN' },
        { abbreviation: 'BF', name: 'Burkina Faso', currency: 'XOF' },
        { abbreviation: 'BI', name: 'Burundi', currency: 'BIF' },
        { abbreviation: 'KH', name: 'Cambodia', currency: 'KHR' },
        { abbreviation: 'CM', name: 'Cameroon', currency: 'XAF' },
        { abbreviation: 'CA', name: 'Canada', currency: 'CAD' },
        { abbreviation: 'CV', name: 'Cape Verde', currency: 'CVE' },
        { abbreviation: 'KY', name: 'Cayman Islands', currency: 'KYD' },
        { abbreviation: 'CF', name: 'Central African Republic', currency: 'XAF' },
        { abbreviation: 'TD', name: 'Chad', currency: 'XAF' },
        { abbreviation: 'CL', name: 'Chile', currency: 'CLP' },
        { abbreviation: 'CN', name: 'China', currency: 'CNY' },
        { abbreviation: 'CX', name: 'Christmas Island', currency: 'AUD' },
        { abbreviation: 'CC', name: 'Cocos (Keeling) Islands', currency: 'AUD' },
        { abbreviation: 'CO', name: 'Colombia', currency: 'COP' },
        { abbreviation: 'KM', name: 'Comoros', currency: 'KMF' },
        { abbreviation: 'CG', name: 'Congo', currency: 'XAF' },
        { abbreviation: 'CD', name: 'Congo, the Democratic Republic of the', currency: 'CDF' },
        { abbreviation: 'CK', name: 'Cook Islands', currency: 'NZD' },
        { abbreviation: 'CR', name: 'Costa Rica', currency: 'CRC' },
        { abbreviation: 'CI', name: 'C\u00f4te d\'Ivoire', currency: 'XOF' },
        { abbreviation: 'HR', name: 'Croatia', currency: 'HRK' },
        { abbreviation: 'CU', name: 'Cuba', currency: 'CUP' },
        { abbreviation: 'CW', name: 'Cura\u00e7ao', currency: 'ANG' },
        { abbreviation: 'CY', name: 'Cyprus', currency: 'EUR' },
        { abbreviation: 'CZ', name: 'Czech Republic', currency: 'CZK' },
        { abbreviation: 'DK', name: 'Denmark', currency: 'DKK' },
        { abbreviation: 'DJ', name: 'Djibouti', currency: 'DJF' },
        { abbreviation: 'DM', name: 'Dominica', currency: 'XCD' },
        { abbreviation: 'DO', name: 'Dominican Republic', currency: 'DOP' },
        { abbreviation: 'EC', name: 'Ecuador', currency: 'USD' },
        { abbreviation: 'EG', name: 'Egypt', currency: 'EGP' },
        { abbreviation: 'SV', name: 'El Salvador', currency: 'SVC' },
        { abbreviation: 'GQ', name: 'Equatorial Guinea', currency: 'XAF' },
        { abbreviation: 'ER', name: 'Eritrea', currency: 'ERN' },
        { abbreviation: 'EE', name: 'Estonia', currency: 'EUR' },
        { abbreviation: 'ET', name: 'Ethiopia', currency: 'ETB' },
        { abbreviation: 'FK', name: 'Falkland Islands (Malvinas)', currency: 'FKP' },
        { abbreviation: 'FO', name: 'Faroe Islands', currency: 'DKK' },
        { abbreviation: 'FJ', name: 'Fiji', currency: 'FJD' },
        { abbreviation: 'FI', name: 'Finland', currency: 'EUR' },
        { abbreviation: 'FR', name: 'France', currency: 'EUR' },
        { abbreviation: 'GF', name: 'French Guiana', currency: 'EUR' },
        { abbreviation: 'PF', name: 'French Polynesia', currency: 'XPF' },
        { abbreviation: 'TF', name: 'French Southern Territories', currency: 'EUR' },
        { abbreviation: 'GA', name: 'Gabon', currency: 'XAF' },
        { abbreviation: 'GM', name: 'Gambia', currency: 'GMD' },
        { abbreviation: 'GE', name: 'Georgia', currency: 'GEL' },
        { abbreviation: 'DE', name: 'Germany', currency: 'EUR' },
        { abbreviation: 'GH', name: 'Ghana', currency: 'GHS' },
        { abbreviation: 'GI', name: 'Gibraltar', currency: 'GIP' },
        { abbreviation: 'GR', name: 'Greece', currency: 'EUR' },
        { abbreviation: 'GL', name: 'Greenland', currency: 'DKK' },
        { abbreviation: 'GD', name: 'Grenada', currency: 'XCD' },
        { abbreviation: 'GP', name: 'Guadeloupe', currency: 'EUR' },
        { abbreviation: 'GU', name: 'Guam', currency: 'USD' },
        { abbreviation: 'GT', name: 'Guatemala', currency: 'GTQ' },
        { abbreviation: 'GG', name: 'Guernsey', currency: 'GBP' },
        { abbreviation: 'GN', name: 'Guinea', currency: 'GNF' },
        { abbreviation: 'GW', name: 'Guinea-Bissau', currency: 'XOF' },
        { abbreviation: 'GY', name: 'Guyana', currency: 'GYD' },
        { abbreviation: 'HT', name: 'Haiti', currency: 'HTG' },
        { abbreviation: 'HM', name: 'Heard Island and McDonald Islands', currency: 'AUD' },
        { abbreviation: 'VA', name: 'Holy See (Vatican City State)', currency: 'EUR' },
        { abbreviation: 'HN', name: 'Honduras', currency: 'HNL' },
        { abbreviation: 'HK', name: 'Hong Kong', currency: 'HKD' },
        { abbreviation: 'HU', name: 'Hungary', currency: 'HUF' },
        { abbreviation: 'IS', name: 'Iceland', currency: 'ISK' },
        { abbreviation: 'IN', name: 'India', currency: 'INR' },
        { abbreviation: 'ID', name: 'Indonesia', currency: 'IDR' },
        { abbreviation: 'IR', name: 'Iran, Islamic Republic of', currency: 'IRR' },
        { abbreviation: 'IQ', name: 'Iraq', currency: 'IQD' },
        { abbreviation: 'IE', name: 'Ireland', currency: 'EUR' },
        { abbreviation: 'IM', name: 'Isle of Man', currency: 'GBP' },
        { abbreviation: 'IL', name: 'Israel', currency: 'ILS' },
        { abbreviation: 'IT', name: 'Italy', currency: 'EUR' },
        { abbreviation: 'JM', name: 'Jamaica', currency: 'JMD' },
        { abbreviation: 'JP', name: 'Japan', currency: 'JPY' },
        { abbreviation: 'JE', name: 'Jersey', currency: 'GBP' },
        { abbreviation: 'JO', name: 'Jordan', currency: 'JOD' },
        { abbreviation: 'KZ', name: 'Kazakhstan', currency: 'KZT' },
        { abbreviation: 'KE', name: 'Kenya', currency: 'KES' },
        { abbreviation: 'KI', name: 'Kiribati', currency: 'AUD' },
        { abbreviation: 'KP', name: 'Korea, Democratic People\'s Republic of', currency: 'KPW' },
        { abbreviation: 'KR', name: 'Korea, Republic of', currency: 'KRW' },
        { abbreviation: 'KW', name: 'Kuwait', currency: 'KWD' },
        { abbreviation: 'KG', name: 'Kyrgyzstan', currency: 'KGS' },
        { abbreviation: 'LA', name: 'Lao People\'s Democratic Republic', currency: 'LAK' },
        { abbreviation: 'LV', name: 'Latvia', currency: 'EUR' },
        { abbreviation: 'LB', name: 'Lebanon', currency: 'LBP' },
        { abbreviation: 'LS', name: 'Lesotho', currency: 'LSL' },
        { abbreviation: 'LR', name: 'Liberia', currency: 'LRD' },
        { abbreviation: 'LY', name: 'Libya', currency: 'LYD' },
        { abbreviation: 'LI', name: 'Liechtenstein', currency: 'CHF' },
        { abbreviation: 'LT', name: 'Lithuania', currency: 'EUR' },
        { abbreviation: 'LU', name: 'Luxembourg', currency: 'EUR' },
        { abbreviation: 'MO', name: 'Macao', currency: 'MOP' },
        { abbreviation: 'MK', name: 'Macedonia', currency: 'MKD' },
        { abbreviation: 'MG', name: 'Madagascar', currency: 'MGA' },
        { abbreviation: 'MW', name: 'Malawi', currency: 'MWK' },
        { abbreviation: 'MY', name: 'Malaysia', currency: 'MYR' },
        { abbreviation: 'MV', name: 'Maldives', currency: 'MVR' },
        { abbreviation: 'ML', name: 'Mali', currency: 'XOF' },
        { abbreviation: 'MT', name: 'Malta', currency: 'EUR' },
        { abbreviation: 'MH', name: 'Marshall Islands', currency: 'USD' },
        { abbreviation: 'MQ', name: 'Martinique', currency: 'EUR' },
        { abbreviation: 'MR', name: 'Mauritania', currency: 'MRU' },
        { abbreviation: 'MU', name: 'Mauritius', currency: 'MUR' },
        { abbreviation: 'YT', name: 'Mayotte', currency: 'EUR' },
        { abbreviation: 'MX', name: 'Mexico', currency: 'MXN' },
        { abbreviation: 'FM', name: 'Micronesia, Federated States of', currency: 'USD' },
        { abbreviation: 'MD', name: 'Moldova, Republic of', currency: 'MDL' },
        { abbreviation: 'MC', name: 'Monaco', currency: 'EUR' },
        { abbreviation: 'MN', name: 'Mongolia', currency: 'MNT' },
        { abbreviation: 'ME', name: 'Montenegro', currency: 'EUR' },
        { abbreviation: 'MS', name: 'Montserrat', currency: 'XCD' },
        { abbreviation: 'MA', name: 'Morocco', currency: 'MAD' },
        { abbreviation: 'MZ', name: 'Mozambique', currency: 'MZN' },
        { abbreviation: 'MM', name: 'Myanmar', currency: 'MMK' },
        { abbreviation: 'NA', name: 'Namibia', currency: 'NAD' },
        { abbreviation: 'NR', name: 'Nauru', currency: 'AUD' },
        { abbreviation: 'NP', name: 'Nepal', currency: 'NPR' },
        { abbreviation: 'NL', name: 'Netherlands', currency: 'EUR' },
        { abbreviation: 'NC', name: 'New Caledonia', currency: 'XPF' },
        { abbreviation: 'NZ', name: 'New Zealand', currency: 'NZD' },
        { abbreviation: 'NI', name: 'Nicaragua', currency: 'NIO' },
        { abbreviation: 'NE', name: 'Niger', currency: 'XOF' },
        { abbreviation: 'NG', name: 'Nigeria', currency: 'NGN' },
        { abbreviation: 'NU', name: 'Niue', currency: 'NZD' },
        { abbreviation: 'NF', name: 'Norfolk Island', currency: 'AUD' },
        { abbreviation: 'MP', name: 'Northern Mariana Islands', currency: 'USD' },
        { abbreviation: 'NO', name: 'Norway', currency: 'NOK' },
        { abbreviation: 'OM', name: 'Oman', currency: 'OMR' },
        { abbreviation: 'PK', name: 'Pakistan', currency: 'PKR' },
        { abbreviation: 'PW', name: 'Palau', currency: 'USD' },
        { abbreviation: 'PS', name: 'Palestine, State of', currency: 'USD' },
        { abbreviation: 'PA', name: 'Panama', currency: 'USD' },
        { abbreviation: 'PG', name: 'Papua New Guinea', currency: 'PGK' },
        { abbreviation: 'PY', name: 'Paraguay', currency: 'PYG' },
        { abbreviation: 'PE', name: 'Peru', currency: 'PEN' },
        { abbreviation: 'PH', name: 'Philippines', currency: 'PHP' },
        { abbreviation: 'PN', name: 'Pitcairn', currency: 'NZD' },
        { abbreviation: 'PL', name: 'Poland', currency: 'PLN' },
        { abbreviation: 'PT', name: 'Portugal', currency: 'EUR' },
        { abbreviation: 'PR', name: 'Puerto Rico', currency: 'USD' },
        { abbreviation: 'QA', name: 'Qatar', currency: 'QAR' },
        { abbreviation: 'RE', name: 'R\u00e9union', currency: 'EUR' },
        { abbreviation: 'RO', name: 'Romania', currency: 'RON' },
        { abbreviation: 'RU', name: 'Russian Federation', currency: 'RUB' },
        { abbreviation: 'RW', name: 'Rwanda', currency: 'RWF' },
        { abbreviation: 'BL', name: 'Saint Barth\u00e9lemy', currency: 'EUR' },
        { abbreviation: 'SH', name: 'Saint Helena, Ascension and Tristan da Cunha', currency: 'SHP' },
        { abbreviation: 'KN', name: 'Saint Kitts and Nevis', currency: 'XCD' },
        { abbreviation: 'LC', name: 'Saint Lucia', currency: 'XCD' },
        { abbreviation: 'MF', name: 'Saint Martin (French part)', currency: 'EUR' },
        { abbreviation: 'PM', name: 'Saint Pierre and Miquelon', currency: 'EUR' },
        { abbreviation: 'VC', name: 'Saint Vincent and the Grenadines', currency: 'XCD' },
        { abbreviation: 'WS', name: 'Samoa', currency: 'WST' },
        { abbreviation: 'SM', name: 'San Marino', currency: 'EUR' },
        { abbreviation: 'ST', name: 'Sao Tome and Principe', currency: 'STN' },
        { abbreviation: 'SA', name: 'Saudi Arabia', currency: 'SAR' },
        { abbreviation: 'SN', name: 'Senegal', currency: 'XOF' },
        { abbreviation: 'RS', name: 'Serbia', currency: 'RSD' },
        { abbreviation: 'SC', name: 'Seychelles', currency: 'SCR' },
        { abbreviation: 'SL', name: 'Sierra Leone', currency: 'SLL' },
        { abbreviation: 'SG', name: 'Singapore', currency: 'SGD' },
        { abbreviation: 'SX', name: 'Sint Maarten (Dutch part)', currency: 'ANG' },
        { abbreviation: 'SK', name: 'Slovakia', currency: 'EUR' },
        { abbreviation: 'SI', name: 'Slovenia', currency: 'EUR' },
        { abbreviation: 'SB', name: 'Solomon Islands', currency: 'SBD' },
        { abbreviation: 'SO', name: 'Somalia', currency: 'SOS' },
        { abbreviation: 'ZA', name: 'South Africa', currency: 'ZAR' },
        { abbreviation: 'GS', name: 'South Georgia and the South Sandwich Islands', currency: 'USD' },
        { abbreviation: 'SS', name: 'South Sudan', currency: 'SSP' },
        { abbreviation: 'ES', name: 'Spain', currency: 'EUR' },
        { abbreviation: 'LK', name: 'Sri Lanka', currency: 'LKR' },
        { abbreviation: 'SD', name: 'Sudan', currency: 'SDG' },
        { abbreviation: 'SR', name: 'Suriname', currency: 'SRD' },
        { abbreviation: 'SJ', name: 'Svalbard and Jan Mayen', currency: 'NOK' },
        { abbreviation: 'SZ', name: 'Swaziland', currency: 'SZL' },
        { abbreviation: 'SE', name: 'Sweden', currency: 'SEK' },
        { abbreviation: 'CH', name: 'Switzerland', currency: 'CHF' },
        { abbreviation: 'SY', name: 'Syrian Arab Republic', currency: 'SYP' },
        { abbreviation: 'TW', name: 'Taiwan', currency: 'TWD' },
        { abbreviation: 'TJ', name: 'Tajikistan', currency: 'TJS' },
        { abbreviation: 'TZ', name: 'Tanzania', currency: 'TZS' },
        { abbreviation: 'TH', name: 'Thailand', currency: 'THB' },
        { abbreviation: 'TL', name: 'Timor-Leste', currency: 'USD' },
        { abbreviation: 'TG', name: 'Togo', currency: 'XOF' },
        { abbreviation: 'TK', name: 'Tokelau', currency: 'NZD' },
        { abbreviation: 'TO', name: 'Tonga', currency: 'TOP' },
        { abbreviation: 'TT', name: 'Trinidad and Tobago', currency: 'TTD' },
        { abbreviation: 'TN', name: 'Tunisia', currency: 'TND' },
        { abbreviation: 'TR', name: 'Turkey', currency: 'TRY' },
        { abbreviation: 'TM', name: 'Turkmenistan', currency: 'TMT' },
        { abbreviation: 'TC', name: 'Turks and Caicos Islands', currency: 'USD' },
        { abbreviation: 'TV', name: 'Tuvalu', currency: 'AUD' },
        { abbreviation: 'UG', name: 'Uganda', currency: 'UGX' },
        { abbreviation: 'UA', name: 'Ukraine', currency: 'UAH' },
        { abbreviation: 'AE', name: 'United Arab Emirates', currency: 'AED' },
        { abbreviation: 'GB', name: 'United Kingdom', currency: 'GBP' },
        { abbreviation: 'US', name: 'United States', currency: 'USD' },
        { abbreviation: 'UM', name: 'United States Minor Outlying Islands', currency: 'USD' },
        { abbreviation: 'UY', name: 'Uruguay', currency: 'UYU' },
        { abbreviation: 'UZ', name: 'Uzbekistan', currency: 'UZS' },
        { abbreviation: 'VU', name: 'Vanuatu', currency: 'VUV' },
        { abbreviation: 'VE', name: 'Venezuela', currency: 'VEF' },
        { abbreviation: 'VN', name: 'Viet Nam', currency: 'VND' },
        { abbreviation: 'VG', name: 'British Virgin Islands', currency: 'USD' },
        { abbreviation: 'VI', name: 'U.S, Virgin Islands', currency: 'USD' },
        { abbreviation: 'WF', name: 'Wallis and Futuna', currency: 'XPF' },
        { abbreviation: 'EH', name: 'Western Sahara', currency: 'MAD' },
        { abbreviation: 'YE', name: 'Yemen', currency: 'YER' },
        { abbreviation: 'ZM', name: 'Zambia', currency: 'ZMW' },
        { abbreviation: 'ZW', name: 'Zimbabwe', currency: 'ZWL' }
    ];

    tax_rates = [
        { tax1name: 'GST', tax1rate: 5, tax2name: '', tax2rate: 0, jurisdiction: 'AB' },
        { tax1name: 'GST', tax1rate: 5, tax2name: 'PST', tax2rate: 7, jurisdiction: 'BC' },
        { tax1name: 'GST', tax1rate: 5, tax2name: 'RST', tax2rate: 7, jurisdiction: 'MB' },
        { tax1name: 'HST', tax1rate: 15, tax2name: '', tax2rate: 0, jurisdiction: 'NB' },
        { tax1name: 'HST', tax1rate: 15, tax2name: '', tax2rate: 0, jurisdiction: 'NL' },
        { tax1name: 'HST', tax1rate: 15, tax2name: '', tax2rate: 0, jurisdiction: 'NS' },
        { tax1name: 'GST', tax1rate: 5, tax2name: '', tax2rate: 0, jurisdiction: 'NT' },
        { tax1name: 'GST', tax1rate: 5, tax2name: '', tax2rate: 0, jurisdiction: 'NU' },
        { tax1name: 'HST', tax1rate: 13, tax2name: '', tax2rate: 0, jurisdiction: 'ON' },
        { tax1name: 'HST', tax1rate: 15, tax2name: '', tax2rate: 0, jurisdiction: 'PE' },
        { tax1name: 'GST', tax1rate: 5, tax2name: 'QST', tax2rate: 9.975, jurisdiction: 'QC' },
        { tax1name: 'GST', tax1rate: 5, tax2name: 'PST', tax2rate: 6, jurisdiction: 'SK' },
        { tax1name: 'GST', tax1rate: 5, tax2name: '', tax2rate: 0, jurisdiction: 'YT' }
    ];

    constructor() { }

    hexCodeForColor(theColor: string): string {
        const defaultColor = '#888888';
        if (theColor.toLowerCase() === 'red') {
            return '#9b1d20';
        }
        if (theColor.toLowerCase() === 'blue') {
            return '#336699';
        }
        if (theColor.toLowerCase() === 'gold') {
            return '#ffc857';
        }
        if (theColor.toLowerCase() === 'green') {
            return '#136f63';
        }
        if (theColor.toLowerCase() === 'purple') {
            return '#5d2e8c';
        }

        // return the default
        return defaultColor;
    }

    public countryFromCode(theAbr: string): string {

        const cnrty = this.countries.find(x => x.abbreviation === theAbr);
        if (cnrty) {
            return cnrty.name;
        } else {
            return 'United States';
        }
    }

    public currencyCodeFromCountryCode(theAbr: string): string {
        const allCntry = this.allcountries.find(x => x.abbreviation === theAbr);
        if (allCntry) {
            return allCntry.currency;
        } else {
            return 'USD';
        }
    }

    public formatBrand(brand: string, last4Digits: string = '', justNumberPattern: boolean = false): string {

        let returnString = '';

        if (!justNumberPattern) {

            switch (brand) {
                case 'visa':
                    returnString = 'VISA Card';
                    break;
                case 'mastercard':
                    returnString = 'MasterCard';
                    break;
                case 'diners':
                    returnString = 'Diners Club';
                    break;
                case 'discover':
                    returnString = 'Discover Card';
                    break;
                case 'jcb':
                    returnString = 'JCB';
                    break;
                case 'amex':
                    returnString = 'American Express';
                    break;
                default:
                    returnString = 'Card';
            }
        }

        if (last4Digits !== '') {

            if (!justNumberPattern) {
                returnString = returnString + ' ';
            }

            switch (brand) {
                case 'visa':
                case 'mastercard':
                case 'diners':
                case 'discover':
                case 'jcb':
                    returnString = returnString + 'XXXX XXXX XXXX ' + last4Digits;
                    break;
                case 'amex':
                    returnString = returnString + 'XXXX XXXXXX X' + last4Digits;
                    break;
                default:
                    returnString = returnString + 'X-' + last4Digits;
            }
        }
        return returnString;
    }

    removeEmpty(obj: any) {
        Object.keys(obj).forEach(key => {
            console.log('key: ' + key + '. value: ' + obj[key]);
            // tslint:disable-next-line:no-unused-expression
            (obj[key] && typeof obj[key] === 'object') && this.removeEmpty(obj[key]) ||
                (obj[key] === '' || obj[key] === null) && delete obj[key];
        });
        return obj;
    }

}
