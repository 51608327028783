import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';

import { environment } from '../environments/environment';
export const firebaseConfig = environment.firebaseConfig;

import { DatePipe } from '@angular/common';

import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireFunctionsModule } from '@angular/fire/functions';

import { AuthService } from './auth/auth.service';
import { SysAdminGuard } from './auth/sysadmin.guard';
import { RegUserGuard } from './auth/reguser.guard';
import { GlobalUtilitiesService } from './utils/global-utils.service';

import { NavFooterComponent } from './nav-footer/nav-footer.component';

import { FileSizePipe } from './pipes/file-size.pipe';
import { ScrollTimeDisplayPipe } from './pipes/scroll-time-display.pipe';
import { TimeDisplayPipe } from './pipes/time-display.pipe';

import { AdminHomePage } from './admin-home-page/admin-home-page.component';
import { UserMgmtDetail } from './user-mgmt-detail/user-mgmt-detail.component';
import { UserMgmtList } from './user-mgmt-list/user-mgmt-list.component';
import { UserMgmtService } from './user-mgmt-service/user-mgmt-service.service';
import { ItemUploadService } from './utils/file-upload/item-upload.service';
import { BlobUploadService } from './utils/file-upload/blob-upload.service';
import { LoadingSpinnerComponent } from './utils/loading-spinner/loading-spinner.component';
import { NotFoundComponent } from './utils/not-found/not-found.component';
import { LoginPage } from './login-page/login-page.component';
import { SiteHomePage } from './site-home-page/site-home-page.component';

import { SampleAddressFormComponent } from './sample-address-form/sample-address-form.component';
import { LayoutModule } from '@angular/cdk/layout';

import { ToastrModule } from 'ngx-toastr';

import { CdkTableModule } from '@angular/cdk/table';
import { FlexLayoutModule } from '@angular/flex-layout';

import { AgmCoreModule } from '@agm/core';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';


import {
  MatButtonModule,
  MatTableModule,
  MatPaginatorModule,
  MatMenuModule,
  MatToolbarModule,
  MatIconModule,
  MatBadgeModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatFormFieldModule,
  MatInputModule,
  MatSortModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatNativeDateModule,
  MatRadioModule,
  MatSelectModule,
  MatSliderModule,
  MatOptionModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatSlideToggleModule, ErrorStateMatcher, ShowOnDirtyErrorStateMatcher, MatSidenavModule, MatListModule, MatTabsModule, MatCheckbox
} from '@angular/material';
import { NavSampleBasComponent } from './nav-sample-bas/nav-sample-bas.component';
import { ProductAdminList } from './product-admin-list/product-admin-list.component';
import { ProductAdminDetail} from './product-admin-detail/product-admin-detail.component';
import { ProductAdminService } from './product-admin-service/product-admin.service';
import { MyNgVarDirectiveDirective } from './directives/my-ng-var-directive.directive';
import { TheCheckoutFromShopifyComponent } from './the-checkout-from-shopify/the-checkout-from-shopify.component';
import { ProductPageComponent } from './product-page/product-page.component';
import { ShoppingCartComponent } from './payments/shopping-cart/shopping-cart.component';
import { CheckoutFlowComponent } from './payments/checkout-flow/checkout-flow.component';
import { ConfirmOrderComponent } from './payments/confirm-order/confirm-order.component';
import { ShoppingCartLineItemComponent } from './payments/shopping-cart-line-item/shopping-cart-line-item.component';
import { BillingAddressDetailComponent } from './address/billing-address-detail-component/billing-address-detail-component.component';
import { AddressEditFormComponent } from './address/address-edit-form/address-edit-form.component';
import { OrdersListComponent } from './orders-list/orders-list.component';
import { OrderDetailComponent } from './order-detail/order-detail.component';
import { CcMaskerPipe } from './pipes/cc-masker.pipe';
import { CcBrandWordPipe } from './pipes/cc-brand-word.pipe';
import { PromoCodeAdminListComponent } from './promo-code-admin-list/promo-code-admin-list.component';
import { PromoCodeAdminDetailComponent } from './promo-code-admin-detail/promo-code-admin-detail.component';
import { OrderManagementService } from './payments/order-management-service/order-management.service';
import { CurrencyService } from './currency-service/currency.service';



@NgModule({
  declarations: [
    AppComponent,
    AdminHomePage,

    NavFooterComponent,
    UserMgmtDetail,
    UserMgmtList,
    LoadingSpinnerComponent,
    NotFoundComponent,
    LoginPage,
    SiteHomePage,
    FileSizePipe,
    ScrollTimeDisplayPipe,
    TimeDisplayPipe,
    SampleAddressFormComponent,
    NavSampleBasComponent,
    ProductAdminList,
    ProductAdminDetail,
    MyNgVarDirectiveDirective,
    TheCheckoutFromShopifyComponent,
    ProductPageComponent,
    ShoppingCartComponent,
    CheckoutFlowComponent,
    ConfirmOrderComponent,
    ShoppingCartLineItemComponent,
    BillingAddressDetailComponent,
    AddressEditFormComponent,
    OrdersListComponent,
    OrderDetailComponent,
    CcMaskerPipe,
    CcBrandWordPipe,
    PromoCodeAdminListComponent,
    PromoCodeAdminDetailComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFirestoreModule.enablePersistence(),
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFireFunctionsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),

    AgmCoreModule.forRoot({
      apiKey: firebaseConfig.apiKey,
      libraries: ['places']
    }),
    MatGoogleMapsAutocompleteModule.forRoot(),

    MatTableModule,
    MatPaginatorModule,
    MatButtonModule,
    MatMenuModule,
    MatToolbarModule,
    MatIconModule,
    MatBadgeModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSortModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatTabsModule,
    MatNativeDateModule,
    MatRadioModule,
    MatSelectModule,
    MatSliderModule,
    MatOptionModule,
    MatSlideToggleModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    FlexLayoutModule,
    LayoutModule,
    MatSidenavModule,
    MatListModule
  ],
  exports: [
    CdkTableModule,
    MatButtonModule,
    MatMenuModule,
    MatTableModule,
    MatPaginatorModule,
    MatToolbarModule,
    MatIconModule,
    MatCardModule,
    MatChipsModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSortModule,
    MatDatepickerModule,
    MatDividerModule,
    MatExpansionModule,
    MatTabsModule,
    MatNativeDateModule,
    MatRadioModule,
    MatSelectModule,
    MatSliderModule,
    MatOptionModule,
    MatSlideToggleModule,
    MatProgressBarModule,
    MatProgressSpinnerModule
  ],
  entryComponents: [],
  providers: [
    AuthService,
    GlobalUtilitiesService,
    SysAdminGuard,
    RegUserGuard,
    ItemUploadService,
    BlobUploadService,
    DatePipe,
    ScrollTimeDisplayPipe,
    TimeDisplayPipe,
    UserMgmtService,
    ProductAdminService,
    OrderManagementService,
    CurrencyService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
