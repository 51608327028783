import { Component, Input, Output, EventEmitter, OnInit, forwardRef } from '@angular/core';
import {
  FormBuilder,
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  FormGroup,
  Validator,
  Validators,
  AbstractControl,
  ValidationErrors
} from '@angular/forms';

import { StreetAddressModel } from '../../payments/models/street-address-model';

import { Location, Appearance } from '@angular-material-extensions/google-maps-autocomplete';
import { GlobalUtilitiesService } from '../../utils/global-utils.service';
import PlaceResult = google.maps.places.PlaceResult;

@Component({
  selector: 'app-address-edit-form',
  templateUrl: './address-edit-form.component.html',
  styleUrls: ['./address-edit-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressEditFormComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AddressEditFormComponent),
      multi: true
    }
  ]
})
export class AddressEditFormComponent implements OnInit, ControlValueAccessor, Validator {

  // this component is set up to be used as a "nested form" of a parent form
  // using the Composit CVA method ... as outlined in this blog post:
  // https://blog.angularindepth.com/angular-nested-reactive-forms-using-cvas-b394ba2e5d0d
  // Thank you Gugan.

  // @Input() public theAddressRecord: StreetAddressModel;

  // @Output() savedForm = new EventEmitter<StreetAddressModel>();

  @Input() public nameCaptureKind = 'firstlast';  // this needs to be 'firstlast', 'allone', or 'noname'
  // this input is to determine if the name capture is part of this form or disabled and if it is part
  // then is it two fields or just one.  This is accomplished by disabling the controls for the name as appropriate.

  addressForm = this.fb.group({

    addressFirstName: ['', Validators.required],
    addressLastName: ['', Validators.required],
    addressFullName: ['', Validators.required],
    addressCompanyName: '',
    addressLine1: ['', Validators.required],
    addressLine2: '',
    addressLine3: '',
    addressCity: ['', Validators.required],
    addressCountyOrRegion: '',
    addressStateOrProvince: ['', Validators.required],
    addressPostalCode: ['',
      Validators.required
      //   [Validators.required, Validators.minLength(5), Validators.maxLength(5)]
    ],
    addressPostalCodeSuffix: '',
    addressCountry: '',
    addressAssociatedPhone: '',

    latitude: 0,
    longitude: 0
  });

  // }, { updateOn: 'blur' });

  hasLine2 = false;
  hasLine3 = false;
  hasCompany = false;

  postalControl: AbstractControl;


  constructor(private fb: FormBuilder, public gu: GlobalUtilitiesService) { }

  ngOnInit() {

    // if (!this.theAddressRecord) {
    //   this.theAddressRecord = new StreetAddressModel();
    // }

    // this.addressForm.valueChanges.subscribe(event => {
    //   console.log('This is the subscribe and this is the event: ', event);
    // });

    // this.addressForm.get('addressFirstName').valueChanges.subscribe(event => {
    //   console.log('This is the subscribe to first name changes and this is the event: ', event);
    // });

    if (this.nameCaptureKind === 'firstlast' || this.nameCaptureKind === 'noname') {
      this.addressForm.get('addressFullName').disable();
    }

    if (this.nameCaptureKind === 'allone' || this.nameCaptureKind === 'noname') {
      this.addressForm.get('addressFirstName').disable();
      this.addressForm.get('addressFirstName').disable();
    }

    this.postalControl = this.addressForm.get('addressPostalCode');
    this.postalControl.valueChanges.subscribe(event => {
      console.log('This is the subscribe to Postal Code changes and this is the event: ', event);
      this.postalControl.patchValue((this.postalControl.value).toUpperCase(), { emitEvent: false });
    });

  }


  public findInvalidControls() {
    const invalid = [];
    const controls = this.addressForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  onAutocompleteSelected(result: PlaceResult) {
    console.log('onAddressSelected: ', result);

    this.getAddress(result);
  }

  onLocationSelected(location: Location) {
    console.log('onLocationSelected: ', location);
    // this.latitude = location.latitude;
    // this.longitude = location.longitude;
  }

  getAddress(place: PlaceResult) {

    console.log('The Formatted address ', place['formatted_address']);
    // console.log("The location ", place['address_components'][0]['long_name'] + ' ' + place['address_components'][1]['long_name']);
    const address_components = place['address_components'];
    console.log('The street ', this.getAddressComponent(address_components, 'street_number', 'short') + ' ' + this.getAddressComponent(address_components, 'route', 'long'));
    console.log('The postal code', this.getAddressComponent(address_components, 'postal_code', 'short'));
    console.log('The city ', this.getAddressComponent(address_components, 'locality', 'short'));
    console.log('The postal town ', this.getAddressComponent(address_components, 'postal_town', 'short'));
    console.log('The State / Province', this.getAddressComponent(address_components, 'administrative_area_level_1', 'short'));
    console.log('The country ', this.getAddressComponent(address_components, 'country', 'short'));


    this.addressForm.get('addressLine1').patchValue(this.getAddressComponent(address_components, 'street_number', 'short')
      + ' ' + this.getAddressComponent(address_components, 'route', 'long'));

    if (this.getAddressComponent(address_components, 'locality', 'long')) {
      this.addressForm.get('addressCity').patchValue(this.getAddressComponent(address_components, 'locality', 'long'));
    } else {
      this.addressForm.get('addressCity').patchValue(this.getAddressComponent(address_components, 'postal_town', 'short'));
    }

    this.addressForm.get('addressStateOrProvince').patchValue(this.getAddressComponent(address_components, 'administrative_area_level_1', 'short'));

    const tempSuffix: string = this.getAddressComponent(address_components, 'postal_code_suffix', 'short');
    this.addressForm.get('addressPostalCode').patchValue(this.getAddressComponent(address_components, 'postal_code', 'short') + ((tempSuffix != null) ? ('-' + tempSuffix) : ''));
    this.addressForm.get('addressPostalCode').markAsDirty();
    this.addressForm.get('addressPostalCode').markAsTouched();

    this.addressForm.get('addressCountry').patchValue(this.getAddressComponent(address_components, 'country', 'short'));

  }

  private getAddressComponent(address, component, type) {
    let element = null;

    for (const entry of address) {
      if (entry.types[0] === component) {
        element = (type === 'short') ? entry.short_name : entry.long_name;
      }
    }
    return element;
  }

  // private patchFormValues() {

  //   this.addressForm.get('addressFirstName').patchValue(this.theAddressRecord.addressFirstName);
  //   this.addressForm.get('addressLastName').patchValue(this.theAddressRecord.addressLastName);
  //   this.addressForm.get('addressCompanyName').patchValue(this.theAddressRecord.addressCompanyName);

  //   this.addressForm.get('addressLine1').patchValue(this.theAddressRecord.addressLine1);
  //   this.addressForm.get('addressLine2').patchValue(this.theAddressRecord.addressLine2);
  //   this.addressForm.get('addressLine3').patchValue(this.theAddressRecord.addressLine3);
  //   this.addressForm.get('addressCity').patchValue(this.theAddressRecord.addressCity);
  //   this.addressForm.get('addressStateOrProvince').patchValue(this.theAddressRecord.addressStateOrProvince);
  //   this.addressForm.get('addressPostalCode').patchValue(this.theAddressRecord.addressPostalCode);
  //   this.addressForm.get('addressCountry').patchValue(this.theAddressRecord.addressCountry);
  //   this.addressForm.get('addressAssociatedPhone').patchValue(this.theAddressRecord.addressAssociatedPhone);

  // }






















  // This is the boilerplate for the Component CVA registration require to use this as a nested form.
  public onTouched: () => void = () => { };

  writeValue(val: any): void {
    // tslint:disable-next-line:no-unused-expression
    val && this.addressForm.setValue(val, { emitEvent: false });
  }
  registerOnChange(fn: any): void {
    console.log('on change');
    this.addressForm.valueChanges.subscribe(fn);
  }
  registerOnTouched(fn: any): void {
    console.log('on blur');
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.addressForm.disable() : this.addressForm.enable();
  }

  validate(c: AbstractControl): ValidationErrors | null {
    // console.log('Address Form validation', c);
    // console.log(this.findInvalidControls());
    return (this.addressForm.valid
      ? null
      : {
        invalidForm: {
          valid: false,
          message: 'Address Form fields are invalid'
        }
      }
    );
  }
}
