import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AuthService } from '../../auth/auth.service';
import { StreetAddressModel } from 'src/app/payments/models/street-address-model';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Injectable({
  providedIn: 'root'
})
export class AddressMgmtService {

  constructor(private afs: AngularFirestore, public authService: AuthService) { }

  getOpenOrdersForUid(theUserUid: string = ''): Observable<StreetAddressModel[]> {

    let theUserUidToUse: string = theUserUid;
    if (theUserUidToUse === '') {
      theUserUidToUse = this.authService.currentUserId();
    }

    const addressesCollection: AngularFirestoreCollection<StreetAddressModel> = this.afs.collection('StreetAddresses', ref => ref.where('creatorUserUid', '==', theUserUidToUse));

    return addressesCollection.valueChanges().pipe(map(items => {
      console.log('Value Changes has returned in the list of street addresses (from service) !!', items);
      return items;
    }));
  }

  getAddressObservable(theAddressUid: string): Observable<StreetAddressModel> {

    const addressDoc: AngularFirestoreDocument<StreetAddressModel> = this.afs.doc('StreetAddresses/' + theAddressUid);

    return addressDoc.valueChanges().pipe(map(item => {
      // Do any conversions here.
      return item;
    }));
  }

  async getAddressDetails(theAddressUid: string): Promise<any> {

    const addressDoc: AngularFirestoreDocument<StreetAddressModel> = this.afs.doc('StreetAddresses/' + theAddressUid);

    return addressDoc.valueChanges().pipe(
      take(1)
    ).toPromise();
  }

  async createNewAddressInDB(theAddress: StreetAddressModel): Promise<any> {
    const addressesCollection: AngularFirestoreCollection<StreetAddressModel> = this.afs.collection('StreetAddresses');

    const newUid = this.afs.createId();
    theAddress.uid = newUid;
    if (this.authService.currentUserId() === '') {
      theAddress.creatorUserUid = 'guest';
    } else {
      theAddress.creatorUserUid = this.authService.currentUserId();
    }

    const addResult = await addressesCollection.doc(newUid).set({ ...theAddress });

    return newUid;
  }

  async updateAddressInDBWithUid(theAddressUid: string, theUpdateJSON: any): Promise<any> {
    const addressDocument: AngularFirestoreDocument<StreetAddressModel> = this.afs.doc('StreetAddresses/' + theAddressUid);

    if (this.authService.currentUserId() === '') {
      theUpdateJSON.lastUpdatorUserUid = 'guest';
    } else {
      theUpdateJSON.lastUpdatorUserUid = this.authService.currentUserId();
    }

    return addressDocument.update(theUpdateJSON) // check returned values for errors
      .then(function () {
        console.log('The Address ' + theAddressUid + ' was successfully updated in the backend DB');
        return;
      })
      .catch(function (error) {
        console.log('there was an error updating the address the backend DB, the error is: ', error);
        throw (error);
      });
  }

  async updateWholeAddressInDB(theAddressObject: StreetAddressModel): Promise<any> {
    const addressDocument: AngularFirestoreDocument<StreetAddressModel> = this.afs.doc('StreetAddresses/' + theAddressObject.uid);

    if (this.authService.currentUserId() === '') {
      theAddressObject.lastUpdatorUserUid = 'guest';
    } else {
      theAddressObject.lastUpdatorUserUid = this.authService.currentUserId();
    }

    return addressDocument.update(theAddressObject) // check returned values for errors
      .then(function () {
        console.log('The Whole Address ' + theAddressObject.uid + ' was successfully updated in the backend DB');
        return;
      })
      .catch(function (error) {
        console.log('there was an error updating the Whole address backend DB, the error is: ', error);
        throw (error);
      });
  }



  async deleteAddress(theAddressUid: string): Promise<any> {

    const addressDocument: AngularFirestoreDocument<StreetAddressModel> = this.afs.doc('StreetAddresses/' + theAddressUid);

    return addressDocument.delete();
  }
}
