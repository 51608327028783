import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import * as _ from 'lodash';
import { take, map, tap } from 'rxjs/operators';






@Injectable()
export class SysAdminGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) { }


  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {

    const hasSysAdmin: boolean = this.authService.hasSysAdminPermissions();

    console.log('in the canActivate of the SysAdminGuard and the SysAdmin permission is: ' + hasSysAdmin + ' !');

    if (hasSysAdmin) {
      return true;
    } else {
      console.log('route prevented!');
      this.router.navigate(['/']);
    }

    // return this.authService.userRoles.pipe(
    //   map(roles => {
    //     if (roles) {
    //       return roles.sysadmin === true;
    //     } else {
    //       return false;
    //     }
    //   }),
    //   tap(authorized => {
    //     if (!authorized) {
    //       console.log('route prevented!');
    //       this.router.navigate(['/']);
    //     }
    //   }));

  }
}
