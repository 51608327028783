import { Component, OnInit } from '@angular/core';
import { ProductAdminService } from '../product-admin-service/product-admin.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { map } from 'rxjs/operators';
import { ProductModelDataType } from '../models/product-model';
import { Location } from '@angular/common';

@Component({
  selector: 'app-product-page',
  templateUrl: './product-page.component.html',
  styleUrls: ['./product-page.component.scss']
})
export class ProductPageComponent implements OnInit {

  public theModelUid;
  theProductModelDetails: ProductModelDataType;

  public loading = true;

  public myurl: String;

  constructor(
    public productAdminServ: ProductAdminService,
    public theRoute: ActivatedRoute,
    public router: Router,
    private location: Location
  ) { }


  async ngOnInit() {
    this.theRoute.params.pipe(
      map((params: Params) => params))
      .subscribe(params => {
        this.theModelUid = params.productId;


      });

    console.log('the Model UID: ', this.theModelUid);

    this.productAdminServ.getProductModelByUid(this.theModelUid).subscribe(returnedProductModel => {
      console.log('theProductModelDetails observable subscription has returned a Product Model and it is:', returnedProductModel);
      this.theProductModelDetails = returnedProductModel;

      // this.myurl = `https://asharplight-one.firebaseapp.com${this.location.path()}`;

      console.log(' The location path is: ', this.location.path());
      console.log(' The full URL is : ', this.myurl);

      this.loading = false;
    });



  }
}
