
export class OrderModel {

    constructor(

        public uid: string = '',
        public orderId: string = '',        // this will be unique and auto generated to a specific format.
        public orderStatus: string = '',    // this will be 'open', 'payment-ready', 'pending-payment', 'payment-complete', 'payment-failed', 'cancelled'
        public orderPrivacy: string = 'none',

        public customerName: string = '',
        public customerEmail: string = '',
        public customerUserUID: string = '',
        public timestampOrderEntered: Date = new Date('01-01-1970'),

        // public billingAddress: StreetAddressModel = new StreetAddressModel(),
        // public shippingAddress: StreetAddressModel  = new StreetAddressModel(),
        public billingAddressUid: string = 'new',
        public shippingAddressUid: string = 'new',

        public shippingStatus: string = '', // this will be 'processing', 'packing', 'shipped', 'received'
        public shippingMethod: string = 'pickup',
        public shippingTrackingNumber: string = '',
        public timestampShipping: Date = new Date('01-01-1970'),
        public shippingCharge: number = 0,
        public shippingCostActual: number = 0,

        public promoCode: string = '',
        public promoApplied: string = 'no',
        public promoIsPercentage: boolean = false,
        public promoAmount: number = 0,
        public promoTotalDiscount: number = 0,
        public commissionDue: number = 0,
        public comissionToWho: string = '',
        public comissionPaid: boolean = false,

        public salesOrVAT1Total: number = 0,
        public salesOrVAT1Jurisdiction: string = '',
        public salesOrVAT1TaxName: string = '',

        public salesOrVAT2Total: number = 0,
        public salesOrVAT2Jurisdiction: string = '',
        public salesOrVAT2TaxName: string = '',

       // now payment methond and not a token or source
        public stripePrebillingObjectRef: string = '',

        public nameOnCard: string = '',
        public stripePrebillingCardBrand: string = '',
        public stripePrebillingCardLast4: string = '',
        public stripePrebillingCardExpMonth: number = 0,
        public stripePrebillingCardExpYear: number = 0,

        public orderSubtotal: number = 0,
        public orderTotalCharge: number = 0,

        public orderPaidInUSD: boolean = true,
        public orderLocalCurrency: string = 'USD',
        public orderAmountInLocalCurrency: number = 0,

        public timestampPaymentConfirmation: Date = new Date('01-01-1970'),
        public stripePaymentIntent: string = '',     // this should probably be private.
        public stripeAmountCaptured: number = 0,

        // public stripePaymentIntentClientSec: string = '',
        // public stripePaymentIntentStatus: string = '',
        public stripePaymentStatus: string = '',
        public stripeChargeResultRef: string = '',

        public pdfInvoiceUrl: string = '',

        // public itemsInOrder: CartLineItemModel[] = [],
        // this is a sub collection

    ) { }


    public fromJSON(obj: any) {
        // console.log('in the from JSON of the WorkerTestscoreModel and the obj is: ', obj);
        for (const key in obj) {
            if (this.hasOwnProperty(key)) {

                this[key] = obj[key];

            }
        }
    }
}
