import { Component, OnInit } from '@angular/core';
import { GlobalUtilitiesService } from './utils/global-utils.service';
import { Title, DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'a-sharp-website-one';

  constructor(
    public gu: GlobalUtilitiesService,
    private titleService: Title,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry.addSvgIcon('forward_1-24px', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/forward_1-24px.svg'));
    this.matIconRegistry.addSvgIcon('my-google-plus', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/fa-svgs/brands/google-plus-g.svg'));
    this.matIconRegistry.addSvgIcon('my-facebook-square', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/fa-svgs/brands/facebook-square.svg'));
    this.matIconRegistry.addSvgIcon('my-facebook', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/fa-svgs/brands/facebook-f.svg'));
    this.matIconRegistry.addSvgIcon('my-heart', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/fa-svgs/solid/heart.svg'));
    this.matIconRegistry.addSvgIcon('my-sign-in', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/fa-svgs/solid/sign-in-alt.svg'));
    this.matIconRegistry.addSvgIcon('my-sign-out', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/fa-svgs/solid/sign-out-alt.svg'));
    this.matIconRegistry.addSvgIcon('my-instagram', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/fa-svgs/brands/instagram.svg'));
    this.matIconRegistry.addSvgIcon('my-youtube', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/fa-svgs/brands/youtube.svg'));
    this.matIconRegistry.addSvgIcon('my-youtube-square', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/fa-svgs/brands/youtube-square.svg'));
    this.matIconRegistry.addSvgIcon('my-twitter', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/fa-svgs/brands/twitter.svg'));
    this.matIconRegistry.addSvgIcon('my-twitter-square', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/fa-svgs/brands/twitter-square.svg'));
  }

  ngOnInit() {
    this.titleService.setTitle( 'A Sharp Light' );
  }
}
