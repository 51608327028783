import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ccMasker'
})
export class CcMaskerPipe implements PipeTransform {

  transform(ccBrand: string, last4Digits: string): string {

    switch (ccBrand) {
      case 'visa':
      case 'mastercard':
      case 'diners':
      case 'discover':
      case 'jcb':

        return 'XXXX XXXX XXXX ' + last4Digits;

      case 'amex':

        return 'XXXX XXXXXX X' + last4Digits;

      default:
        return 'X-' + last4Digits;
    }
  }
}
