import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { StreetAddressModel } from '../../payments/models/street-address-model';

@Component({
  selector: 'app-billing-address-detail-component',
  templateUrl: './billing-address-detail-component.component.html',
  styleUrls: ['./billing-address-detail-component.component.scss']
})
export class BillingAddressDetailComponent implements OnInit {

  @Input() public theAddressRecord: StreetAddressModel;

  @Input() public theEmailAddress: string;

  @Input() public theUseBillingAsShippingFlag = true;

  @Output() shippingToggled = new EventEmitter<string>();

  // public billingInfoForm: FormGroup = new FormGroup({
  //   emailAddress: new FormControl(''),
  //   useBillingForShipping: new FormControl(false)
  // });

  public billingInfoForm = this.fb.group({

    emailAddress: [null, Validators.required],
    useBillingForShipping: true,
    addressDetailsNestedForm: null
  });



  constructor(private fb: FormBuilder) { }

  ngOnInit() {
  }

  public onSubmit() {
    console.log('Form Values', this.billingInfoForm.value);
  }

  public shippingToggle() {
    if (this.theUseBillingAsShippingFlag) {
      this.shippingToggled.emit('toOff');
      console.log('toggle shipping off');
    } else {
      this.shippingToggled.emit('toOn');
      console.log('toggle shipping on');
    }
  }
}
