import { Component, OnInit } from '@angular/core';
import { ProductAdminService } from '../product-admin-service/product-admin.service';
import { AsyncValidatorFn, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { ProductModelDataType } from '../models/product-model';
import { MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { map } from 'rxjs/operators';

import * as _ from 'lodash';


export function checkForUniqueModelNumberValidatorExternalFn(theService: ProductAdminService): AsyncValidatorFn {
  return (modelNumberControl: AbstractControl): Promise<{ [key: string]: any } | null> | Observable<{ [key: string]: any } | null> => {
    console.log('In the Check for Unique Model Number Validator');

    return theService.isModelNumberAvailable(modelNumberControl.value)
      .then(result => {
        console.log('In the Check for Unique Model Number Validator - promise then and the result is: ', result);
        if (result) {
          return null;
        } else {
          return { 'notUnique': true };
        }

      });
  };
}

export function checkForUniqueSKUValidatorExternalFn(theService: ProductAdminService, theExistingSKU: string): AsyncValidatorFn {
  return (skuControl: AbstractControl): Promise<{ [key: string]: any } | null> | Observable<{ [key: string]: any } | null> => {
    console.log('In the Check for Unique SKU Validator');

    return theService.isSKUAvailable(skuControl.value, theExistingSKU)
      .then(result => {
        console.log('In the Check for Unique SKU Validator - promise then and the result is: ', result);
        if (result) {
          return null;
        } else {
          return { 'notUnique': true };
        }

      });
  };
}



@Component({
  selector: 'app-product-admin-detail',
  templateUrl: './product-admin-detail.component.html',
  styleUrls: ['./product-admin-detail.component.scss']
})
// tslint:disable-next-line:component-class-suffix
export class ProductAdminDetail implements OnInit {

  public theModelUid;

  public loading = true;
  public editing = false;
  public saving = false;
  public hasTable = false;

  public productModelEditForm;

  theProductModelDetails$: Observable<ProductModelDataType>;
  theProductModelDetails: ProductModelDataType;

  displayedColumns = ['single'];
  dataSource: MatTableDataSource<any>;

  sortIndex: string[] = ['asc', 'none', 'none', 'none'];

  constructor(
    public productAdminServ: ProductAdminService,
    private fb: FormBuilder,
    public theRoute: ActivatedRoute,
    public router: Router
  ) { }

  async ngOnInit() {
    this.theRoute.params.pipe(
      map((params: Params) => params))
      .subscribe(params => {
        this.theModelUid = params.productId;
      });

    console.log('the Model UID: ', this.theModelUid);

    this.productModelEditForm = this.fb.group({
      // ProductModelNumber: ['', { updateOn: 'blur', validators: [Validators.required], asyncValidators: [checkForUniqueModelNumberValidatorExternalFn(this.modelFamilyService)] }],
      modelNumber: ['', [Validators.required]],
      sku: ['', [Validators.required]],
      name: ['', [Validators.required]],
      description: '',
      weightInGrams: 0,
      weightInOzs: 0,
      priceInUSD: 0,
      priceInCAD: 0,
      dateIntroduced: '',
      hasSerialNumber: false,
      isBundle: false,
      isActive: true
    });

    if (this.theModelUid === 'new') {
      console.log('creating a new Model ... do I need anything here ??');

      this.productModelEditForm.get('modelNumber').setAsyncValidators(
        [checkForUniqueModelNumberValidatorExternalFn(this.productAdminServ)]
      );

      this.productModelEditForm.get('sku').setAsyncValidators(
        [checkForUniqueSKUValidatorExternalFn(this.productAdminServ, '')]
      );

      this.editing = true;
      this.loading = false;
    } else {

      await this.initializeObservablesAndSubscriptions();

    }
  }

  private async initializeObservablesAndSubscriptions() {
    console.log('starting the initialize Observables method on the product model details page - Model UID is: ', this.theModelUid);
    this.theProductModelDetails$ = this.productAdminServ.getProductModelByUid(this.theModelUid).pipe(map(itemd => {
      console.log('Value Changes has returned in the Product Model Data (component)!!', itemd);
      return itemd;
    }));

    this.theProductModelDetails$.subscribe(returnedProductModel => {
      console.log('theProductModelDetails observable subscription has returned a Product Model and it is:', returnedProductModel);
      this.theProductModelDetails = returnedProductModel;

      if (returnedProductModel.hasSerialNumber) {

        this.hasTable = true;

        // this.productAdminServ.getAllBuiltDevicesForModelNumber(this.theProductModelDetails.modelNumber).subscribe(theCreatedDevices => {
        //   this.dataSource = new MatTableDataSource(theCreatedDevices);
        //   this.sortColumn(0);
        // });
        // this may need to go outside this other subscribe.

      }

      this.productModelEditForm.get('sku').setAsyncValidators(
        [checkForUniqueSKUValidatorExternalFn(this.productAdminServ, (this.theProductModelDetails ? this.theProductModelDetails.sku : ''))]
      );

      this.loading = false;
    });


  }

  editProductModelDetails() {

    this.productModelEditForm.patchValue(this.theProductModelDetails);

    this.editing = true;
  }

  cancel() {
    if (!this.saving) {  // ignore multiple button presses.
      if (this.theModelUid === 'new') {
        this.router.navigate(['/admin/productlist']);
      } else {
        this.editing = false;
        this.productModelEditForm.patchValue(this.theProductModelDetails);
      }
    }
  }




  async saveChanges() {
    console.log('I\'m in the save changes method of the model Product Model component, the form is: ', this.productModelEditForm.value);
    if (!this.saving) {  // ignore multiple button presses.

      this.saving = true;

      if (this.theModelUid === 'new') {
        // need to create theProduct Model in Firestore !!!
        await this.productAdminServ.createNewProductModel(this.productModelEditForm.value)
          .then(resultOne => {
            console.log('The new Product Model was created and its UID is: ', resultOne);

            this.theModelUid = resultOne;
            this.loading = true;
            this.editing = false;
            this.saving = false;

          })
          .catch(error => {
            console.log('some stupid error occurred creating the new Product Model: ', error);
            this.editing = false;
            this.saving = false;
          });

        this.initializeObservablesAndSubscriptions();


      } else {
        await this.productAdminServ.updateProductModelByUid(this.theModelUid, this.productModelEditForm.value);

        this.editing = false;
        this.saving = false;
      }
    }
  }






  sortColumn(theColNumber: number) { /// for the actual devices that have been created.

    console.log('in the sortColumn routine !! for column: ', theColNumber);
    console.log('the data source data is: ', this.dataSource.data);

    if (this.dataSource.data.length > 0) {

      if (theColNumber === 0) {
        if (this.sortIndex[0] === 'asc') {
          this.dataSource.data = _.orderBy(this.dataSource.data, 'serialNumber', 'desc');
          this.sortIndex[0] = 'desc';
        } else {
          this.dataSource.data = _.orderBy(this.dataSource.data, 'serialNumber', 'asc');
          this.sortIndex[0] = 'asc';
        }
        this.sortIndex[1] = 'none';
        this.sortIndex[2] = 'none';
        this.sortIndex[3] = 'none';
      } else if (theColNumber === 1) {
        if (this.sortIndex[1] === 'asc') {
          this.dataSource.data = _.orderBy(this.dataSource.data, 'dateOfManufacture', 'desc');
          this.sortIndex[1] = 'desc';
        } else {
          this.dataSource.data = _.orderBy(this.dataSource.data, 'dateOfManufacture', 'asc');
          this.sortIndex[1] = 'asc';
        }
        this.sortIndex[0] = 'none';
        this.sortIndex[2] = 'none';
        this.sortIndex[3] = 'none';
      } else if (theColNumber === 2) {
        if (this.sortIndex[2] === 'asc') {
          this.dataSource.data = _.orderBy(this.dataSource.data, 'dateSetupInDB', 'desc');
          this.sortIndex[2] = 'desc';
        } else {
          this.dataSource.data = _.orderBy(this.dataSource.data, 'dateSetupInDB', 'asc');
          this.sortIndex[2] = 'asc';
        }
        this.sortIndex[0] = 'none';
        this.sortIndex[1] = 'none';
        this.sortIndex[3] = 'none';
      } else if (theColNumber === 3) {
        if (this.sortIndex[3] === 'asc') {
          this.dataSource.data = _.orderBy(this.dataSource.data, 'particleIdHasBeenAssociated', 'desc');
          this.sortIndex[3] = 'desc';
        } else {
          this.dataSource.data = _.orderBy(this.dataSource.data, 'particleIdHasBeenAssociated', 'asc');
          this.sortIndex[3] = 'asc';
        }
        this.sortIndex[0] = 'none';
        this.sortIndex[1] = 'none';
        this.sortIndex[2] = 'none';
      }
    }
  }


}
