import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
// import { firebase } from '@firebase/app';

import { AuthService } from '../auth/auth.service';

import { Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { ProductModelDataType } from '../models/product-model';


@Injectable({
  providedIn: 'root'
})
export class ProductAdminService {

  constructor(private afs: AngularFirestore, private authService: AuthService) { }

  async isModelNumberAvailable(theRequestedNumber: string): Promise<boolean> {

    const theModelNumbers =
      await this.afs.collection('ProductModels').valueChanges().pipe(
        take(1),
        map(items => {
          return items.map(itemd => {
            const thisItem: any = itemd;
            return thisItem.modelNumber;
          });

        })
      ).toPromise();

    for (let i = 0; i < theModelNumbers.length; i++) {
      const tempID: string = theModelNumbers[i];

      if (tempID.toLowerCase() === theRequestedNumber.toLowerCase()) {
        return false;
      }
    }
    // not found
    return true;
  }

  async isSKUAvailable(theRequestedSKU: string, theCurrentSKU: string): Promise<boolean> {

    console.log('In the Service for Unique SKU check with requested: ' + theRequestedSKU + ' and the current: ' + theCurrentSKU + ' !!');

    if (theRequestedSKU === theCurrentSKU) {
      return true;
    }

    const theProductSKUs =
      await this.afs.collection('ProductModels').valueChanges().pipe(
        take(1),
        map(items => {
          return items.map(itemd => {
            const thisItem: any = itemd;
            return thisItem.sku;
          });

        })
      ).toPromise();

    for (let i = 0; i < theProductSKUs.length; i++) {
      const tempSKU: string = theProductSKUs[i];

      if (tempSKU.toLowerCase() === theRequestedSKU.toLowerCase()) {
        return false;
      }
    }
    // not found
    return true;
  }

  getAllProductModels(): Observable<ProductModelDataType[]> {

    let ProductModelCollection: AngularFirestoreCollection<ProductModelDataType>;

    if (this.authService.hasSysAdminPermissions()) {
      ProductModelCollection = this.afs.collection('ProductModels'); // reference
    } else {
      console.log('THIS SHOULD NOT HAPPEN >>> ONLY SYS ADMINS SHOULD BE CALLING THIS - THROW AN EXCEPTION');
      return null;
    }

    return ProductModelCollection.valueChanges().pipe(map(items => {
      console.log('Value Changes has returned in the list of product models (from service) !!', items);
      return items.map(itemd => {
        itemd.dateIntroduced = (itemd.dateIntroduced as unknown as firebase.firestore.Timestamp).toDate();
        itemd.dateDiscontinued = (itemd.dateDiscontinued as unknown as firebase.firestore.Timestamp).toDate();
        return itemd;
      });
    }));
  }

  getAllActiveProductModelsPublic(): Observable<ProductModelDataType[]> {

    const ProductModelCollection: AngularFirestoreCollection<ProductModelDataType> = this.afs.collection('ProductModels', ref => ref.where('isActive', '==', true));

    return ProductModelCollection.valueChanges().pipe(map(items => {
      console.log('Value Changes has returned in the list of product models (from service) !!', items);
      return items.map(itemd => {
        itemd.dateIntroduced = (itemd.dateIntroduced as unknown as firebase.firestore.Timestamp).toDate();
        itemd.dateDiscontinued = (itemd.dateDiscontinued as unknown as firebase.firestore.Timestamp).toDate();
        return itemd;
      });
    }));
  }



  async createNewProductModel(theNewJSON: any): Promise<any> {

    const ProductModelCollection: AngularFirestoreCollection<ProductModelDataType> = this.afs.collection('ProductModels'); // reference

    const newUid = this.afs.createId();

    const newProductModel: ProductModelDataType = new ProductModelDataType();
    newProductModel.fromJSON(theNewJSON);

    newProductModel.uid = newUid;

    const addResult = await ProductModelCollection.doc(newUid).set({ ...newProductModel });

    return newUid;
  }

  getProductModelByUid(theModelId: string): Observable<ProductModelDataType> {

    const theProductModelDoc: AngularFirestoreDocument<ProductModelDataType> = this.afs.doc('ProductModels/' + theModelId); // reference

    return theProductModelDoc.valueChanges().pipe(map(itemd => {
      if (itemd) {
        itemd.dateIntroduced = (itemd.dateIntroduced as unknown as firebase.firestore.Timestamp).toDate();
        itemd.dateDiscontinued = (itemd.dateDiscontinued as unknown as firebase.firestore.Timestamp).toDate();

        console.log('Value Changes has returned in the single Product Model (from Service) !!', itemd);
        return itemd;
      }
    }));
  }

  getProductModelByModelNumber(theModelNumber: string): Observable<ProductModelDataType> {

    const ProductModelCollection: AngularFirestoreCollection<ProductModelDataType> = this.afs.collection('ProductModels', ref => ref.where('modelNumber', '==', theModelNumber));

    return ProductModelCollection.valueChanges().pipe(map(items => {
      if (items) {
        items[0].dateIntroduced = (items[0].dateIntroduced as unknown as firebase.firestore.Timestamp).toDate();
        items[0].dateDiscontinued = (items[0].dateDiscontinued as unknown as firebase.firestore.Timestamp).toDate();

        console.log('Value Changes has returned in the single Product Model (from Service by ModelNumber) !!', items[0]);
        return items[0];
      }
    }));
  }

  updateProductModelByUid(theModelId: string, theUpdateJSON: any): Promise<any> {
    const theProductModelDoc: AngularFirestoreDocument<ProductModelDataType> = this.afs.doc('ProductModels/' + theModelId); // reference

    return theProductModelDoc.update(theUpdateJSON) // check returned values for errors
      .then(function () {
        console.log('The Product Model Data was successfully updated in the backend DB');
        return;
      })
      .catch(function (error) {
        console.log('there was an error updating the Product Model Data in the backend DB, the error is: ', error);
        throw (error);
      });
  }

  async deleteProductModelByUid(theModelId: string) {

    const theProductModelDoc: AngularFirestoreDocument<ProductModelDataType> = this.afs.doc('ProductModels/' + theModelId); // reference
    await theProductModelDoc.delete();

  }

}
