import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTable, MatSort, MatTableDataSource } from '@angular/material';

import { PromoCodeAdminService } from '../promo-code-admin-service/promo-code-admin-service.service';
import { PromoCodeModel } from '../models/promo-code-model';

import * as _ from 'lodash';


@Component({
  selector: 'app-promo-code-admin-list',
  templateUrl: './promo-code-admin-list.component.html',
  styleUrls: ['./promo-code-admin-list.component.scss']
})
export class PromoCodeAdminListComponent implements OnInit {

  @ViewChild(MatTable, {static: true}) dataTable: MatTable<PromoCodeModel>;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  // @ViewChild(MatPaginator, {static: true}) customPaginator: MatPaginator;

  displayedColumns = ['single'];
  dataSource: MatTableDataSource<any>;

  loading = true;

  sortIndex: string[] = ['asc', 'none', 'none', 'none', 'none'];
  // uniqueCode, longName, amount, isPercentage, expirationEndDate with descripton on line below

  productModelsList: any[] = [];


  constructor(private promoCodeAdminService: PromoCodeAdminService) { }

  ngOnInit() {

    console.log('Getting the list or Promo Codes ');

    this.promoCodeAdminService.getAllPromoCodes().subscribe(theCodesList => {
      this.dataSource = new MatTableDataSource(theCodesList);

      this.sortColumn(0);

      this.loading = false;

    });

  }

  sortColumn(theColNumber: number) {

    console.log('in the sortColumn routine !! for column: ', theColNumber);
    console.log('the data source data is: ', this.dataSource.data);

    if (theColNumber === 0) {
      if (this.sortIndex[0] === 'asc') {
        this.dataSource.data = _.orderBy(this.dataSource.data, 'uniqueCode', 'desc');
        this.sortIndex[0] = 'desc';
      } else {
        this.dataSource.data = _.orderBy(this.dataSource.data, 'uniqueCode', 'asc');
        this.sortIndex[0] = 'asc';
      }
      this.sortIndex[1] = 'none';
      this.sortIndex[2] = 'none';
      this.sortIndex[3] = 'none';
      this.sortIndex[4] = 'none';
    } else if (theColNumber === 1) {
      if (this.sortIndex[1] === 'asc') {
        this.dataSource.data = _.orderBy(this.dataSource.data, 'longName', 'desc');
        this.sortIndex[1] = 'desc';
      } else {
        this.dataSource.data = _.orderBy(this.dataSource.data, 'longName', 'asc');
        this.sortIndex[1] = 'asc';
      }
      this.sortIndex[0] = 'none';
      this.sortIndex[2] = 'none';
      this.sortIndex[3] = 'none';
      this.sortIndex[4] = 'none';
    } else if (theColNumber === 2) {
      if (this.sortIndex[2] === 'asc') {
        this.dataSource.data = _.orderBy(this.dataSource.data, 'amount', 'desc');
        this.sortIndex[2] = 'desc';
      } else {
        this.dataSource.data = _.orderBy(this.dataSource.data, 'amount', 'asc');
        this.sortIndex[2] = 'asc';
      }
      this.sortIndex[0] = 'none';
      this.sortIndex[1] = 'none';
      this.sortIndex[3] = 'none';
      this.sortIndex[4] = 'none';
    } else if (theColNumber === 3) {
      if (this.sortIndex[3] === 'asc') {
        this.dataSource.data = _.orderBy(this.dataSource.data, 'isPercentage', 'desc');
        this.sortIndex[3] = 'desc';
      } else {
        this.dataSource.data = _.orderBy(this.dataSource.data, 'isPercentage', 'asc');
        this.sortIndex[3] = 'asc';
      }
      this.sortIndex[0] = 'none';
      this.sortIndex[1] = 'none';
      this.sortIndex[2] = 'none';
      this.sortIndex[4] = 'none';
    } else if (theColNumber === 4) {
      if (this.sortIndex[4] === 'asc') {
        this.dataSource.data = _.orderBy(this.dataSource.data, 'expirationEndDate', 'desc');
        this.sortIndex[4] = 'desc';
      } else {
        this.dataSource.data = _.orderBy(this.dataSource.data, 'expirationEndDate', 'asc');
        this.sortIndex[4] = 'asc';
      }
      this.sortIndex[0] = 'none';
      this.sortIndex[1] = 'none';
      this.sortIndex[2] = 'none';
      this.sortIndex[3] = 'none';
    }
  }

}
