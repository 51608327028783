import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { GlobalUtilitiesService } from '../utils/global-utils.service';
// import { MarkupTagTextValuesService } from '../markup-tag-text-values-service/markup-tag-text-values-service.service';
// import { VoiceRecordingComponent } from '../voice-recording/voice-recording.component';
// import { CouponCodeService } from '../coupon-code-service/coupon-code-service.service';

@Component({
  selector: 'app-admin-home-page',
  templateUrl: './admin-home-page.component.html',
  styleUrls: ['./admin-home-page.component.scss']
})
// tslint:disable-next-line:component-class-suffix
export class AdminHomePage implements OnInit {

  constructor(
    public authService: AuthService,
    public gu: GlobalUtilitiesService,
    // public tagTextValuesService: MarkupTagTextValuesService,
    // public couponService: CouponCodeService
  ) { }

  ngOnInit() {
  }

  // loadCoreTagData() {
  //   this.tagTextValuesService.loadTagDataFromAFile('TagTextValuesCore.json', 'CoreTagValues');
  // }
}

