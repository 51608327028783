import * as moment from 'moment-timezone';

export class CartLineItemModel {

    constructor(

        public uid: string = '',
        public sku: string = '',
        public name: string = '',
        public description: string = '',
        public modelNumber: string = '',
        public price: number = 0,
        public currency: string = 'USD',
        public quantity: number = 1,
        public lineItemSubtotal: number = 0,
        public thumbnailPhotoURL: string = '',
        public timestampAddedToCart: Date = new Date(),

        public purchaseStatus: string = 'incart',  // these will be 'incart', 'purchased' and once purchased the items cannot be updated or removed
        public timestampPurchased: Date = new Date(),

        public timestampLastUpdated: Date = new Date(),

    ) { }


    public fromJSON(obj: any) {
        // console.log('in the from JSON of the WorkerTestscoreModel and the obj is: ', obj);
        for (const key in obj) {
            if (this.hasOwnProperty(key)) {

                this[key] = obj[key];

            }
        }
    }
}
