import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { OrderManagementService } from '../payments/order-management-service/order-management.service';


// import { Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';

import { PromoCodeModel } from '../models/promo-code-model';

@Injectable({
  providedIn: 'root'
})
export class PromoCodeUserService {


  constructor(
    private afs: AngularFirestore,
    private orderMgmtService: OrderManagementService,
    private afFun: AngularFireFunctions
  ) { }


  public async checkPromoCodeIsValid(theCodeString: string): Promise<any> {

    const theUpperCaseCode = theCodeString.toUpperCase();
    const theCollection: AngularFirestoreCollection<PromoCodeModel> = this.afs.collection('PromoCodes', ref => ref.where('uniqueCode', '==', theUpperCaseCode));
    const checkResult =
      await theCollection.valueChanges().pipe(
        take(1),
        map(items => {
          return items.map(itemd => {
            itemd.activeStartDate = (itemd.activeStartDate as unknown as firebase.firestore.Timestamp).toDate();
            itemd.expirationEndDate = (itemd.expirationEndDate as unknown as firebase.firestore.Timestamp).toDate();
            return itemd;
          });
        })
      ).toPromise();

    if (checkResult.length > 0) {
      if (checkResult[0].expirationEndDate < new Date()) {
        return { result: 'is expired' };
      } else {
        return { result: 'valid' };
      }
    } else {
      return { result: 'does not exist' };
    }
  }


  public async addPromoCodeToOrderUid(theCodeString: string, theOrderUid: string) {

    try {
      const theUpperCaseCode = theCodeString.toUpperCase();
      const theCollection: AngularFirestoreCollection<PromoCodeModel> = this.afs.collection('PromoCodes', ref => ref.where('uniqueCode', '==', theUpperCaseCode));
      const theCodeDetails: PromoCodeModel[] =
        await theCollection.valueChanges().pipe(
          take(1),
          map(items => {
            return items.map(itemd => {
              itemd.activeStartDate = (itemd.activeStartDate as unknown as firebase.firestore.Timestamp).toDate();
              itemd.expirationEndDate = (itemd.expirationEndDate as unknown as firebase.firestore.Timestamp).toDate();
              return itemd;
            });
          })
        ).toPromise();

        // console.log('what is returned ??');
        // console.log(theCodeDetails);

      // prepare codeJSON
      const codeJSON: any = {};
      codeJSON.promoCode = theCodeDetails[0].uniqueCode;
      codeJSON.promoApplied = 'yes';
      codeJSON.promoIsPercentage = theCodeDetails[0].isPercentage;
      codeJSON.promoAmount = theCodeDetails[0].amount;
      // need to figure out how to set the amount for this.
      // codeJSON.commissionDue = theCodeDetails[0].??;
      codeJSON.comissionToWho = theCodeDetails[0].commissionGoesTo;
      codeJSON.comissionPaid = false;

      await this.orderMgmtService.updateOrderByUid(theOrderUid, codeJSON);

    } catch (error) {
      console.log('AN ERROR OCCURRED adding a Promo code on the Order - this should not happen');
      console.log(error);

      throw error;
    }

  }

  public async removePromoCodeFromOrderUid(theOrderUid: string) {

    try {

      // prepare codeJSON
      const codeJSON: any = {};
      codeJSON.promoCode = '';
      codeJSON.promoApplied = 'no';
      codeJSON.promoIsPercentage = false;
      codeJSON.promoAmount = 0;
      codeJSON.promoTotalDiscount = 0;
      codeJSON.commissionDue = 0;
      codeJSON.comissionToWho = '';
      codeJSON.comissionPaid = false;

      await this.orderMgmtService.updateOrderByUid(theOrderUid, codeJSON);

    } catch (error) {
      console.log('AN ERROR OCCURRED removing a Promo code on the Order - this should not happen');
      console.log(error);

      throw error;
    }

  }

  public async replaceExistingPromoCodeFromOrderUid(withNewCode: string, theOrderUid: string) {

    // this is just a pass through for now
    return await this.addPromoCodeToOrderUid(withNewCode, theOrderUid);

  }

}
