
import { LoginPage } from './login-page/login-page.component';
import { SiteHomePage } from './site-home-page/site-home-page.component';
import { AdminHomePage } from './admin-home-page/admin-home-page.component';
import { SysAdminGuard } from './auth/sysadmin.guard';
import { UserMgmtList } from './user-mgmt-list/user-mgmt-list.component';
import { UserMgmtDetail } from './user-mgmt-detail/user-mgmt-detail.component';
import { NotFoundComponent } from './utils/not-found/not-found.component';
import { SampleAddressFormComponent } from './sample-address-form/sample-address-form.component';
import { ProductAdminList } from './product-admin-list/product-admin-list.component';
import { ProductAdminDetail } from './product-admin-detail/product-admin-detail.component';
// import { TheCheckoutFromShopifyComponent } from './the-checkout-from-shopify/the-checkout-from-shopify.component';
import { ShoppingCartComponent } from './payments/shopping-cart/shopping-cart.component';
import { ProductPageComponent } from './product-page/product-page.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OrderDetailComponent } from './order-detail/order-detail.component';
import { OrdersListComponent } from './orders-list/orders-list.component';
import { PromoCodeAdminListComponent } from './promo-code-admin-list/promo-code-admin-list.component';
import { PromoCodeAdminDetailComponent } from './promo-code-admin-detail/promo-code-admin-detail.component';


const routes: Routes = [
  { path: '', component: SiteHomePage },
  { path: 'login', component: LoginPage },
  // { path: 'checkout', component: TheCheckoutFromShopifyComponent},
  { path: 'checkout', component: ShoppingCartComponent },
  { path: 'address', component: SampleAddressFormComponent },

  { path: 'orders', component: OrdersListComponent },
  { path: 'orders/:orderId', component: OrderDetailComponent },

  // { path: 'newevaluation/:method', component: NewEvaluationFormComponent},
  // { path: 'evaluationslist/:listType', component: EvaluationsListPage},
  // { path: 'evaluation/:evalId', component: EvaluationDetailPage},

  { path: 'product/:productId', component: ProductPageComponent},

  { path: 'admin', component: AdminHomePage, canActivate: [SysAdminGuard] },
  { path: 'admin/usermgmtlist', component: UserMgmtList, canActivate: [SysAdminGuard] },
  { path: 'admin/usermgmt/:userId', component: UserMgmtDetail, canActivate: [SysAdminGuard] },
  { path: 'admin/productlist', component: ProductAdminList, canActivate: [SysAdminGuard] },
  { path: 'admin/productadmin/:productId', component: ProductAdminDetail, canActivate: [SysAdminGuard] },
  { path: 'admin/promolist', component: PromoCodeAdminListComponent, canActivate: [SysAdminGuard] },
  { path: 'admin/promoadmin/:codestring', component: PromoCodeAdminDetailComponent, canActivate: [SysAdminGuard] },


  // { path: 'form/:theAgencyName', component: FormPage },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
