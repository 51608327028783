import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ccBrandWord'
})
export class CcBrandWordPipe implements PipeTransform {

  transform(ccBrand: string): string {

    switch (ccBrand) {
        case 'visa':
            return 'VISA Card';
        case 'mastercard':
            return 'MasterCard';
        case 'diners':
            return 'Diners Club';
        case 'discover':
            return 'Discover Card';
        case 'jcb':
            return 'JCB';
        case 'amex':
            return 'American Express';
        default:
            return 'Card';
    }
  }
}
