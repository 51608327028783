import { Component, OnInit, Input, Output, EventEmitter, OnChanges, AfterViewInit, OnDestroy } from '@angular/core';
import { CartLineItemModel } from '../models/cart-line-item-model';

@Component({
  selector: 'app-shopping-cart-line-item',
  templateUrl: './shopping-cart-line-item.component.html',
  styleUrls: ['./shopping-cart-line-item.component.scss']
})
export class ShoppingCartLineItemComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

  @Input() theLineItem: CartLineItemModel;
  @Input() isReadOnly = false;

  @Output() addOrSubtractQuantity = new EventEmitter<string>();

  @Output() removeItem = new EventEmitter<string>();

  public updating = false;

  constructor() { }

  ngOnInit() {
    // console.log('in On Init ... so set local quantity value here. the Line Item is: ', this.theLineItem);
  }

  ngAfterViewInit() {
    // console.log('in After View Init ... so set local quantity value here. the Line Item is: ', this.theLineItem);
  }

  ngOnChanges(changes) {

    // console.log('In the line Item and the On changes hook has been called', changes);

    // console.log('And the state values is ... ', this.theLineItem);

  }

  ngOnDestroy() {

    // console.log('In the line Item and the On Destroy hook has been called.  The Line Item is: ', this.theLineItem);
  }

  async increaseQuantity(byNum: number = 1) {
    this.updating = true;
    // this.theLineItem.quantity++;
    // this.theLineItem.lineItemSubtotal = this.theLineItem.quantity * this.theLineItem.price;
    // push it up to the parent.
    this.addOrSubtractQuantity.emit('up');
  }

  async decreaseQuantity(byNum: number = 1) {

    if (this.theLineItem.quantity > 1) {
      this.updating = true;
      // this.theLineItem.quantity--;
      // this.theLineItem.lineItemSubtotal = this.theLineItem.quantity * this.theLineItem.price;

      // push it up to the parent.
      this.addOrSubtractQuantity.emit('down');
    }
  }

  async setQuantity(toNum: number) {
    this.theLineItem.quantity = toNum;
    this.theLineItem.lineItemSubtotal = this.theLineItem.quantity * this.theLineItem.price;

    // push it up to the parent.
  }

  async removeThisItem() {
    this.updating = true;

    this.removeItem.emit(this.theLineItem.uid);
  }

}
