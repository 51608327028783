import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTable, MatSort, MatTableDataSource } from '@angular/material';
import { ProductModelDataType } from '../models/product-model';
import { ProductAdminService } from '../product-admin-service/product-admin.service';

import * as _ from 'lodash';

@Component({
  selector: 'app-product-admin-list',
  templateUrl: './product-admin-list.component.html',
  styleUrls: ['./product-admin-list.component.scss']
})
// tslint:disable-next-line:component-class-suffix
export class ProductAdminList implements OnInit {

  @ViewChild(MatTable, {static: true}) dataTable: MatTable<ProductModelDataType>;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  // @ViewChild(MatPaginator, {static: true}) customPaginator: MatPaginator;

  displayedColumns = ['single'];
  dataSource: MatTableDataSource<any>;

  loading = true;

  sortIndex: string[] = ['asc', 'none', 'none', 'none', 'none'];
  // sku, modelNumber, name, isBundle, dateIntroducted with descripton on line below

  productModelsList: any[] = [];


  constructor(private productAdminServ: ProductAdminService) { }

  ngOnInit() {

    console.log('Getting the list or Product Models ');

    this.productAdminServ.getAllProductModels().subscribe(theModelsList => {
      this.dataSource = new MatTableDataSource(theModelsList);

      this.sortColumn(0);

      this.loading = false;

    });

  }

  sortColumn(theColNumber: number) {

    console.log('in the sortColumn routine !! for column: ', theColNumber);
    console.log('the data source data is: ', this.dataSource.data);

    if (theColNumber === 0) {
      if (this.sortIndex[0] === 'asc') {
        this.dataSource.data = _.orderBy(this.dataSource.data, 'sku', 'desc');
        this.sortIndex[0] = 'desc';
      } else {
        this.dataSource.data = _.orderBy(this.dataSource.data, 'sku', 'asc');
        this.sortIndex[0] = 'asc';
      }
      this.sortIndex[1] = 'none';
      this.sortIndex[2] = 'none';
      this.sortIndex[3] = 'none';
      this.sortIndex[4] = 'none';
    } else if (theColNumber === 1) {
      if (this.sortIndex[1] === 'asc') {
        this.dataSource.data = _.orderBy(this.dataSource.data, 'modelNumber', 'desc');
        this.sortIndex[1] = 'desc';
      } else {
        this.dataSource.data = _.orderBy(this.dataSource.data, 'modelNumber', 'asc');
        this.sortIndex[1] = 'asc';
      }
      this.sortIndex[0] = 'none';
      this.sortIndex[2] = 'none';
      this.sortIndex[3] = 'none';
      this.sortIndex[4] = 'none';
    } else if (theColNumber === 2) {
      if (this.sortIndex[2] === 'asc') {
        this.dataSource.data = _.orderBy(this.dataSource.data, 'name', 'desc');
        this.sortIndex[2] = 'desc';
      } else {
        this.dataSource.data = _.orderBy(this.dataSource.data, 'name', 'asc');
        this.sortIndex[2] = 'asc';
      }
      this.sortIndex[0] = 'none';
      this.sortIndex[1] = 'none';
      this.sortIndex[3] = 'none';
      this.sortIndex[4] = 'none';
    } else if (theColNumber === 3) {
      if (this.sortIndex[3] === 'asc') {
        this.dataSource.data = _.orderBy(this.dataSource.data, 'isBundle', 'desc');
        this.sortIndex[3] = 'desc';
      } else {
        this.dataSource.data = _.orderBy(this.dataSource.data, 'isBundle', 'asc');
        this.sortIndex[3] = 'asc';
      }
      this.sortIndex[0] = 'none';
      this.sortIndex[1] = 'none';
      this.sortIndex[2] = 'none';
      this.sortIndex[4] = 'none';
    } else if (theColNumber === 4) {
      if (this.sortIndex[4] === 'asc') {
        this.dataSource.data = _.orderBy(this.dataSource.data, 'dateIntroduced', 'desc');
        this.sortIndex[4] = 'desc';
      } else {
        this.dataSource.data = _.orderBy(this.dataSource.data, 'dateIntroduced', 'asc');
        this.sortIndex[4] = 'asc';
      }
      this.sortIndex[0] = 'none';
      this.sortIndex[1] = 'none';
      this.sortIndex[2] = 'none';
      this.sortIndex[3] = 'none';
    }
  }

}
