import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { CurrencyCodeModel } from '../models/currency-code-model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {

  constructor(private afs: AngularFirestore) { }

  getCurrencyDetailsAndCurrentRate(theCurrencyCode: string): Observable<CurrencyCodeModel> {

    const theCurencyDetailsDoc: AngularFirestoreDocument<CurrencyCodeModel> = this.afs.doc('USDExchange/' + theCurrencyCode); // reference

    return theCurencyDetailsDoc.valueChanges().pipe(map(itemd => {
      if (itemd) {
        itemd.lastUpdated = (itemd.lastUpdated as unknown as firebase.firestore.Timestamp).toDate();

        console.log('Value Changes has returned in the single Currency details (from Service) !!', itemd);
        return itemd;
      }
    }));
  }
}
