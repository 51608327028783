// import * as moment from 'moment-timezone';

export class PromoCodeModel {

    constructor(

        public uniqueCode: string = '',
        public longName: string = '',
        public description: string = '',
        public isPercentage: boolean = false,
        public amount: number = 0,
        public commissionGoesTo: string = '',

        public activeStartDate: Date = new Date(),
        public expirationEndDate: Date = new Date(),

    ) { }


    public fromJSON(obj: any) {
        // console.log('in the from JSON of the WorkerTestscoreModel and the obj is: ', obj);
        for (const key in obj) {
            if (this.hasOwnProperty(key)) {

                this[key] = obj[key];

            }
        }
    }
}
