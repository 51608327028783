import { Component, OnInit } from '@angular/core';
import { AsyncValidatorFn, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';

import { MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { map } from 'rxjs/operators';

import * as _ from 'lodash';
import { PromoCodeAdminService } from '../promo-code-admin-service/promo-code-admin-service.service';
import { PromoCodeModel } from '../models/promo-code-model';

export function checkForUniqueCodeValidatorExternalFn(theService: PromoCodeAdminService): AsyncValidatorFn {
  return (skuControl: AbstractControl): Promise<{ [key: string]: any } | null> | Observable<{ [key: string]: any } | null> => {
    console.log('In the Check for Unique Code Validator');

    return theService.isCodeStringAvailable(skuControl.value)
      .then(result => {
        console.log('In the Check for Unique Code String Validator - promise then and the result is: ', result);
        if (result) {
          return null;
        } else {
          return { 'notUnique': true };
        }

      });
  };
}


@Component({
  selector: 'app-promo-code-admin-detail',
  templateUrl: './promo-code-admin-detail.component.html',
  styleUrls: ['./promo-code-admin-detail.component.scss']
})
export class PromoCodeAdminDetailComponent implements OnInit {

  public theCodeString;

  public loading = true;
  public editing = false;
  public saving = false;
  public hasTable = false;

  public promoCodeEditForm;

  thePromoCodeDetails$: Observable<PromoCodeModel>;
  thePromoCodeDetails: PromoCodeModel;

  displayedColumns = ['single'];
  dataSource: MatTableDataSource<any>;

  sortIndex: string[] = ['asc', 'none', 'none', 'none'];

  constructor(
    public promoCodeAdminService: PromoCodeAdminService,
    private fb: FormBuilder,
    public theRoute: ActivatedRoute,
    public router: Router
  ) { }

  async ngOnInit() {
    this.theRoute.params.pipe(
      map((params: Params) => params))
      .subscribe(params => {
        this.theCodeString = params.codestring;
      });

    console.log('The Promo Code String: ', this.theCodeString);

    this.promoCodeEditForm = this.fb.group({
      // ProductModelNumber: ['', { updateOn: 'blur', validators: [Validators.required], asyncValidators: [checkForUniqueModelNumberValidatorExternalFn(this.modelFamilyService)] }],
      uniqueCode: ['', [Validators.required]],
      longName: '',
      description: '',
      amount: 0,
      commissionGoesTo: '',
      activeStartDate: '',
      expirationEndDate: '',
      isPercentage: false,

    });

    if (this.theCodeString === 'new') {
      console.log('creating a new promo code ... do I need anything here ??');

      this.promoCodeEditForm.get('uniqueCode').setAsyncValidators(
        [checkForUniqueCodeValidatorExternalFn(this.promoCodeAdminService)]
      );

      this.editing = true;
      this.loading = false;
    } else {

      await this.initializeObservablesAndSubscriptions();

    }
  }

  private async initializeObservablesAndSubscriptions() {
    console.log('starting the initialize Observables method on the promo code admin details page - unique code is: ', this.theCodeString);
    this.thePromoCodeDetails$ = this.promoCodeAdminService.getPromoCodeRecord(this.theCodeString).pipe(map(itemd => {
      console.log('Value Changes has returned in the Promo Code Data (component)!!', itemd);
      return itemd;
    }));

    this.thePromoCodeDetails$.subscribe(returnedPromoCode => {
      console.log('thePromoCodeDetails observable subscription has returned a Promo Code and it is:', returnedPromoCode);
      this.thePromoCodeDetails = returnedPromoCode;


      // Add this as a table to Show where used
      // if (returnedPromoCode.hasSerialNumber) {

      //   this.hasTable = true;

      //   // this.promoCodeAdminService.getAllBuiltDevicesForModelNumber(this.thePromoCodeDetails.modelNumber).subscribe(theCreatedDevices => {
      //   //   this.dataSource = new MatTableDataSource(theCreatedDevices);
      //   //   this.sortColumn(0);
      //   // });
      //   // this may need to go outside this other subscribe.

      // }

      // this.promoCodeEditForm.get('sku').setAsyncValidators(
      //   [checkForUniqueSKUValidatorExternalFn(this.promoCodeAdminService, (this.thePromoCodeDetails ? this.thePromoCodeDetails.sku : ''))]
      // );

      this.loading = false;
    });


  }

  editPromoCodeDetails() {

    this.promoCodeEditForm.patchValue(this.thePromoCodeDetails);

    this.editing = true;
  }

  cancel() {
    if (!this.saving) {  // ignore multiple button presses.
      if (this.theCodeString === 'new') {
        this.router.navigate(['/admin/productlist']);
      } else {
        this.editing = false;
        this.promoCodeEditForm.patchValue(this.thePromoCodeDetails);
      }
    }
  }




  async saveChanges() {
    console.log('I\'m in the save changes method of the model Promo Code detail component, the form is: ', this.promoCodeEditForm.value);
    if (!this.saving) {  // ignore multiple button presses.

      this.saving = true;

      if (this.theCodeString === 'new') {
        // need to create theProduct Model in Firestore !!!
        await this.promoCodeAdminService.createNewPromoCode(this.promoCodeEditForm.value)
          .then(resultOne => {
            console.log('The new Promo Code was created and its results is: ', resultOne);

            if (resultOne.result === 'success') {
              this.theCodeString = resultOne.theCodeString;
              this.loading = true;
              this.editing = false;
              this.saving = false;
            } else {
              console.log('some stupid error occurred creating the new Promo Code: ', resultOne.message);
              this.editing = false;
              this.saving = false;
            }

          })
          .catch(error => {
            console.log('some stupid error occurred creating the new Promo Code: ', error);
            this.editing = false;
            this.saving = false;
          });

        this.initializeObservablesAndSubscriptions();


      } else {
        await this.promoCodeAdminService.updatePromoCode(this.theCodeString, this.promoCodeEditForm.value);

        this.editing = false;
        this.saving = false;
      }
    }
  }






  sortColumn(theColNumber: number) { /// for the Orders where this promo code was used.

    console.log('in the sortColumn routine !! for column: ', theColNumber);
    console.log('the data source data is: ', this.dataSource.data);

    if (this.dataSource.data.length > 0) {

      if (theColNumber === 0) {
        if (this.sortIndex[0] === 'asc') {
          this.dataSource.data = _.orderBy(this.dataSource.data, 'orderId', 'desc');
          this.sortIndex[0] = 'desc';
        } else {
          this.dataSource.data = _.orderBy(this.dataSource.data, 'orderId', 'asc');
          this.sortIndex[0] = 'asc';
        }
        this.sortIndex[1] = 'none';
        this.sortIndex[2] = 'none';
        this.sortIndex[3] = 'none';
      } else if (theColNumber === 1) {
        if (this.sortIndex[1] === 'asc') {
          this.dataSource.data = _.orderBy(this.dataSource.data, 'timestampPaymentConfirmation', 'desc');
          this.sortIndex[1] = 'desc';
        } else {
          this.dataSource.data = _.orderBy(this.dataSource.data, 'timestampPaymentConfirmation', 'asc');
          this.sortIndex[1] = 'asc';
        }
        this.sortIndex[0] = 'none';
        this.sortIndex[2] = 'none';
        this.sortIndex[3] = 'none';
      } else if (theColNumber === 2) {
        if (this.sortIndex[2] === 'asc') {
          this.dataSource.data = _.orderBy(this.dataSource.data, 'orderStatus', 'desc');
          this.sortIndex[2] = 'desc';
        } else {
          this.dataSource.data = _.orderBy(this.dataSource.data, 'orderStatus', 'asc');
          this.sortIndex[2] = 'asc';
        }
        this.sortIndex[0] = 'none';
        this.sortIndex[1] = 'none';
        this.sortIndex[3] = 'none';
      } else if (theColNumber === 3) {
        if (this.sortIndex[3] === 'asc') {
          this.dataSource.data = _.orderBy(this.dataSource.data, 'customerName', 'desc');
          this.sortIndex[3] = 'desc';
        } else {
          this.dataSource.data = _.orderBy(this.dataSource.data, 'customerName', 'asc');
          this.sortIndex[3] = 'asc';
        }
        this.sortIndex[0] = 'none';
        this.sortIndex[1] = 'none';
        this.sortIndex[2] = 'none';
      }
    }
  }


}
