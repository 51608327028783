import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AuthService } from '../auth/auth.service';

import { Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';

import { PromoCodeModel } from '../models/promo-code-model';

@Injectable({
  providedIn: 'root'
})
export class PromoCodeAdminService {

  constructor(private afs: AngularFirestore, private authService: AuthService) { }

  async isCodeStringAvailable(theRequestedCode: string): Promise<boolean> {

    const theUpperCaseCode = theRequestedCode.toUpperCase();

    const checkResult =
      await this.afs.collection('PromoCodes', ref => ref.where('uniqueCode', '==', theUpperCaseCode)).valueChanges().pipe(
        take(1),
        map(items => {
          return items;
        })
      ).toPromise();

    if (checkResult.length > 0) {
      return false;
    } else {
      return true;
    }
  }

  getAllPromoCodes(): Observable<PromoCodeModel[]> {

    let PromoCodesCollection: AngularFirestoreCollection<PromoCodeModel>;

    if (this.authService.hasSysAdminPermissions()) {
      PromoCodesCollection = this.afs.collection('PromoCodes'); // reference
    } else {
      console.log('THIS SHOULD NOT HAPPEN >>> ONLY SYS ADMINS SHOULD BE CALLING THIS - THROW AN EXCEPTION');
      return null;
    }

    return PromoCodesCollection.valueChanges().pipe(map(items => {
      console.log('Value Changes has returned in the list of promo codes (from service) !!', items);
      return items.map(itemd => {
        itemd.activeStartDate = (itemd.activeStartDate as unknown as firebase.firestore.Timestamp).toDate();
        itemd.expirationEndDate = (itemd.expirationEndDate as unknown as firebase.firestore.Timestamp).toDate();
        return itemd;
      });
    }));
  }

  getAllActivePromoCodes(): Observable<PromoCodeModel[]> {

    // *** TEST IF THIS ACTUALLY WORKS ******
    const PromoCodesCollection: AngularFirestoreCollection<PromoCodeModel> = this.afs.collection('PromoCodes', ref => ref.where('expirationEndDate', '>=', new Date()));

    return PromoCodesCollection.valueChanges().pipe(map(items => {
      console.log('Value Changes has returned in the list of active promo codes (from service) !!', items);
      return items.map(itemd => {
        itemd.activeStartDate = (itemd.activeStartDate as unknown as firebase.firestore.Timestamp).toDate();
        itemd.expirationEndDate = (itemd.expirationEndDate as unknown as firebase.firestore.Timestamp).toDate();
        return itemd;
      });
    }));
  }



  async createNewPromoCode(theNewJSON: any): Promise<any> {

    const PromoCodesCollection: AngularFirestoreCollection<PromoCodeModel> = this.afs.collection('PromoCodes'); // reference

    if (this.isCodeStringAvailable(theNewJSON.uniqueCode)) {

      const newPromoCode: PromoCodeModel = new PromoCodeModel();
      newPromoCode.fromJSON(theNewJSON);
      newPromoCode.uniqueCode = theNewJSON.uniqueCode.toUpperCase();

      const addResult = await PromoCodesCollection.doc(newPromoCode.uniqueCode).set({ ...newPromoCode });

      return { result: 'success', theCodeString: newPromoCode.uniqueCode };
    } else {
      return { result: 'failed', message: 'This code has already been used!'};
    }
  }

  getPromoCodeRecord(theUniqueCode: string): Observable<PromoCodeModel> {

    const thePromoCodeDoc: AngularFirestoreDocument<PromoCodeModel> = this.afs.doc('PromoCodes/' + theUniqueCode); // reference

    return thePromoCodeDoc.valueChanges().pipe(map(itemd => {
      if (itemd) {
        itemd.activeStartDate = (itemd.activeStartDate as unknown as firebase.firestore.Timestamp).toDate();
        itemd.expirationEndDate = (itemd.expirationEndDate as unknown as firebase.firestore.Timestamp).toDate();

        console.log('Value Changes has returned in the single Promo Code (from Service) !!', itemd);
        return itemd;
      }
    }));
  }

  updatePromoCode(theUniqueCode: string, theUpdateJSON: any): Promise<any> {
    const thePromoCodeDoc: AngularFirestoreDocument<PromoCodeModel> = this.afs.doc('PromoCodes/' + theUniqueCode); // reference

    return thePromoCodeDoc.update(theUpdateJSON) // check returned values for errors
      .then(function () {
        console.log('The Promo Code Data was successfully updated in the backend DB');
        return;
      })
      .catch(function (error) {
        console.log('there was an error updating the Promo Code Data in the backend DB, the error is: ', error);
        throw (error);
      });
  }

  async deletePromoCodeByUid(theUniqueCode: string) {

    const thePromoCodeDoc: AngularFirestoreDocument<PromoCodeModel> = this.afs.doc('PromoCodes/' + theUniqueCode); // reference
    await thePromoCodeDoc.delete();

  }

}
