import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { OrderModel } from '../payments/models/order-model';
import { OrderManagementService } from '../payments/order-management-service/order-management.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { map } from 'rxjs/operators';
import { StreetAddressModel } from '../payments/models/street-address-model';
import { AddressMgmtService } from '../address/address-mgmt-service/address-mgmt.service';
import { GlobalUtilitiesService } from '../utils/global-utils.service';
import { CartLineItemModel } from '../payments/models/cart-line-item-model';

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss']
})
export class OrderDetailComponent implements OnInit {

  @Input() public orderId: string;

  // theUserMgmtDoc: AngularFirestoreDocument<UserMgmtModel>;
  theOrderDoc$: Observable<OrderModel>;
  theBilling$: Observable<StreetAddressModel>;
  theShipping$: Observable<StreetAddressModel>;
  theLineItems$: Observable<CartLineItemModel[]>;

  public isGeneratingPDF = false;

  constructor(
    public orderMgmtService: OrderManagementService,
    public addressMgmtService: AddressMgmtService,
    public gu: GlobalUtilitiesService,
    private afs: AngularFirestore,
    public theRoute: ActivatedRoute,
    public router: Router
  ) { }


  ngOnInit() {
    this.theRoute.params.pipe(
      map((params: Params) => params)
    )
      .subscribe(params => this.orderId = params.orderId.toString().toUpperCase());

    console.log('the Order Id is: ', this.orderId);


    this.theOrderDoc$ = this.orderMgmtService.getOrderWithOrderId(this.orderId).pipe(
      map(itemd => {
        console.log('Value Changes has returned in the Order Data (component)!!', itemd);
        this.theBilling$ = this.addressMgmtService.getAddressObservable(itemd.billingAddressUid).pipe(
          map(billAdd => {
            console.log('Billing address details received in Order Details (component)!!', billAdd);
            return billAdd;
          })
        );
        this.theShipping$ = this.addressMgmtService.getAddressObservable(itemd.shippingAddressUid).pipe(
          map(shipAdd => {
            console.log('Shipping address details received in Order Details (component)!!', shipAdd);
            return shipAdd;
          })
        );

        this.theLineItems$ = this.orderMgmtService.getLineItemsForOrder(itemd.uid).pipe(
          map(tempLIs => {
            console.log('Line Items received in Order Details (component)!!', tempLIs);
            return tempLIs;
          })
        );

        return itemd;
      }));

  }

  public async generateReceipt( theOrderUid: string ) {

    this.isGeneratingPDF = true;
    console.log('About to call the service to generate a PDF Receipt and the Order ID is: ', theOrderUid);
    const receiptResult = await this.orderMgmtService.generatePDFReceiptForOrder(theOrderUid);
    console.log('In order detail comp after the call to generate the PDF receipt and the returned value is: ', receiptResult);
    this.isGeneratingPDF = false;
  }

  public async sendEmailReceipt( theOrderUid: string ) {
    console.log('About to call the service to email a Receipt and the Order ID is: ', theOrderUid);
    const receiptResult = await this.orderMgmtService.resendEmailReceiptForOrder(theOrderUid);
    console.log('In order detail comp after the call to email a Receipt and the returned value is: ', receiptResult);
  }

}
