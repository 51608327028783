import * as moment from 'moment-timezone';

export class StreetAddressModel {

    constructor(

        public uid: string = '',
        public creatorUserUid: string = '',     // keep this for GDPR tracking & removal
        public lastUpdatorUserUid: string = '',     // keep this for GDPR tracking & removal

        // public associatedOrderUids: string[] = [],

        public saveAsNickName: string = '',

        public addressFirstName: string = '',
        public addressLastName: string = '',

        public addressCompanyName: string = '',

        public addressLine1: string = '',
        public addressLine2: string = '',
        public addressLine3: string = '',
        public addressCity: string = '',
        public addressCountyOrRegion: string = '',
        public addressStateOrProvince: string = '',
        public addressPostalCode: string = '',
        public addressPostalCodeSuffix: string = '',
        public addressCountry: string = '',
        public addressAssociatedPhone: string = '',

        public latitude: number = 0,
        public longitude: number = 40.

    ) { }


    public fromJSON(obj: any) {
        // console.log('in the from JSON of the WorkerTestscoreModel and the obj is: ', obj);
        for (const key in obj) {
            if (this.hasOwnProperty(key)) {

                this[key] = obj[key];

            }
        }
    }
}
