import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';

import { AuthService } from '../../auth/auth.service';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { OrderModel } from '../models/order-model';
import { map, take, tap, switchMap } from 'rxjs/operators';
import { CartLineItemModel } from '../models/cart-line-item-model';
// import { StreetAddressModel } from '../models/street-address-model';


@Injectable({
  providedIn: 'root'
})
export class OrderManagementService implements OnDestroy {

  // want to maintain some state here in the service for the currently open order in this session if there is one
  // and manage the process of how that changes when the user logs in or logs out.

  public numberOfItemsInCurrentOrder$: BehaviorSubject<number> = new BehaviorSubject(0);
  public currentOpenOrderUserOrNot$: BehaviorSubject<OrderModel | null> = new BehaviorSubject(null);
  public isUserCurrentlyLoggedIn$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private theCurrentUserUid = 'no user';
  private theUserDataSubscription: Subscription;
  private theOpenOrderSubscription: Subscription;
  private theLineItemForCountSubscrption: Subscription;

  constructor(
    private afs: AngularFirestore,
    private authService: AuthService,
    private afFun: AngularFireFunctions
  ) {

    console.log('In the Constructor of the OrderMgmtService ... this should only happen once per session');
    this.theUserDataSubscription = this.authService.userData.subscribe({
      next: async (theUser) => {
        console.log('!!! In the User Mgmt Service constructor subscription the user subscription has fired !!!');
        console.log('The User Value is: ', theUser);

        if (theUser) {
          console.log('!!!! There IS a user');
          this.isUserCurrentlyLoggedIn$.next(true);
          this.theCurrentUserUid = theUser.uid;
          // Check for an existing cart in Local Storage ... if it is there then bring it in and make it the users active cart.
          // if the user already has an active cart then add it's line items to the active cart.

          const localOpenOrderDocUid = await this.checkForLocalOrderUid();
          if (localOpenOrderDocUid) {
            console.log('!!! In the User Mgmt Service constructor subscription - there IS a local open order doc for a non-logged in user');
            // check to see if there is an open order for the user !
            const userOpenOrderDocsList = await this.getJustTheUidForOpenOrdersForUser(theUser.uid).pipe(
              take(1)
            ).toPromise();

            let newOpenOrderDocUid: string;

            if (userOpenOrderDocsList.length > 0) {
              console.log('There is a open order already for this user.');
              newOpenOrderDocUid = userOpenOrderDocsList[0].uid;
              // already an open order for this user ... so add the items for the previous.
              const localOrderLineItems: CartLineItemModel[] = await this.getLineItemsForOrder(localOpenOrderDocUid).pipe(
                take(1)
              ).toPromise();

              console.log('got the line items for my cart and they are: ', localOrderLineItems);

              for (let liX = 0; liX < localOrderLineItems.length; liX++) {
                const tempLineItem = localOrderLineItems[liX];
                await this.addLineItemToOpenCartForUser(this.theCurrentUserUid, tempLineItem);
              }

              // I think I need to manage this subscription
              if (this.theOpenOrderSubscription) {
                this.theOpenOrderSubscription.unsubscribe();
              }
              console.log('set order id behaviour subject 1 !!!!');
              this.theOpenOrderSubscription = this.getOrderWithUid(newOpenOrderDocUid).subscribe(tempOrder => {
                if (tempOrder.orderStatus === 'open') {
                  this.currentOpenOrderUserOrNot$.next(tempOrder);
                } else {
                  this.currentOpenOrderUserOrNot$.next(null);
                }
              });

              console.log('DO I EVER GET HERE');
              // now delete the old order - or something like that.
              await this.deepDeleteOfOrder(localOpenOrderDocUid);


              console.log('DO I EVER GET HERE 2');


              // remove the reference in local storage.
              localStorage.removeItem('localStoredOrderUid');



            } else {
              console.log('There WAS NOT an open order already for this user.');
              // no open order for this user so just make the local order id into the open order for this user.
              newOpenOrderDocUid = localOpenOrderDocUid;
              await this.updateOrderByUid(localOpenOrderDocUid, { customerUserUID: theUser.uid });

              // remove the reference in local storage.
              localStorage.removeItem('localStoredOrderUid');

              // I think I need to manage this subscription
              if (this.theOpenOrderSubscription) {
                this.theOpenOrderSubscription.unsubscribe();
              }
              console.log('set order id behaviour subject 2 !!!!');
              this.theOpenOrderSubscription = this.getOrderWithUid(localOpenOrderDocUid).subscribe(tempOrder => {
                if (tempOrder.orderStatus === 'open') {
                  this.currentOpenOrderUserOrNot$.next(tempOrder);
                } else {
                  this.currentOpenOrderUserOrNot$.next(null);
                }
              });

            }
            // if there is not an existing subscription to the list then create it.  (code copied from below)
            // if (!this.associatedOrderUidsSubscription) {
            //   this.associatedOrderUids$ = this.orderMgmtService.getJustTheUidForOpenOrdersForUser(theUser.uid).pipe(map(itemd => {
            //     console.log('Value Changes has returned an observable of the Pending Order UID (component)!!', itemd);
            //     return itemd;
            //   }));

            //   this.associatedOrderUidsSubscription = this.associatedOrderUids$.subscribe(returnedOrderList => {

            //     console.log('In the Cart - Subcribe to the UID of the order for the logged in user');

            //     if (returnedOrderList.length > 0) {

            //       // Only do the subscription the first time and if the result ever changes which it shouldn't unless you do something on another tab.
            //       if (this.loading) {
            //         console.log('In the Cart - Subcribe to the UID of the order for Loading is true so set up order observable.');
            //         this.setUpObservableForOpenOrder(returnedOrderList[0].uid);
            //       } else if (this.associatedOrderUid !== returnedOrderList[0].uid) {
            //         console.log('In the Cart - Subcribe to the UID of the order - the order uid has changed so rest tthe order observable.');
            //         // kill the existing subscription and then do a new one.
            //         if (this.associatedOrderOneSubscription) {
            //           this.associatedOrderOneSubscription.unsubscribe();
            //         }
            //         this.setUpObservableForOpenOrder(returnedOrderList[0].uid);
            //       }

            //     } else {
            //       console.log('THE LIST IS EMPTY ... DOES THIS GET CALLED ??');
            //       this.currentOrderIsEmpty = true;
            //     }
            //   });
            // }
          } else {
            // else there is not local storage order -- just set the open order to the open order for the user.
            const userOpenOrderDocsList = await this.getJustTheUidForOpenOrdersForUser(theUser.uid).pipe(
              take(1)
            ).toPromise();

            if (userOpenOrderDocsList.length > 0) {

              // I think I need to manage this subscription
              if (this.theOpenOrderSubscription) {
                this.theOpenOrderSubscription.unsubscribe();
              }
              console.log('set order id behaviour subject 7 !!!!');
              this.theOpenOrderSubscription = this.getOrderWithUid(userOpenOrderDocsList[0].uid).subscribe(tempOrder => {
                if (tempOrder.orderStatus === 'open') {
                  this.currentOpenOrderUserOrNot$.next(tempOrder);
                } else {
                  this.currentOpenOrderUserOrNot$.next(null);
                }
              });

            } else {

              console.log('set order id behaviour subject 8 !!!!');
              if (this.theOpenOrderSubscription) {
                this.theOpenOrderSubscription.unsubscribe();
              }
              this.currentOpenOrderUserOrNot$.next(null);
            }


          }

        } else {
          console.log('!!!! There is NOT a user');
          this.isUserCurrentlyLoggedIn$.next(false);
          this.theCurrentUserUid = 'no user';

          const localNoUserOpenOrderDocUid = await this.checkForLocalOrderUid();
          if (localNoUserOpenOrderDocUid) {

            // I think I need to manage this subscription
            if (this.theOpenOrderSubscription) {
              this.theOpenOrderSubscription.unsubscribe();
            }
            console.log('set order id behaviour subject 3 !!!!');
            this.theOpenOrderSubscription = this.getOrderWithUid(localNoUserOpenOrderDocUid).subscribe(tempOrder => {
              if (tempOrder.orderStatus === 'open') {
                this.currentOpenOrderUserOrNot$.next(tempOrder);
              } else {
                this.currentOpenOrderUserOrNot$.next(null);
              }
            });

          } else {
            console.log('set order id behaviour subject 4 !!!!');
            if (this.theOpenOrderSubscription) {
              this.theOpenOrderSubscription.unsubscribe();
            }
            this.currentOpenOrderUserOrNot$.next(null);
          }
        }
      },
      error: (theError) => {
        console.log('An ERROR occurred getting the User Observable from the AuthService');
      }
    });
    // end of subscribe.

    // subscibe to the current open order and set the item count whenever it changes.
    this.currentOpenOrderUserOrNot$.subscribe(tempOrder => {
      // using a pipe and tap here vs subscription ... perhaps this does not leak memory
      console.log('$$$*** The Current open Order has changed and it is now: ', tempOrder);

      if (tempOrder) {
        console.log('in the subscribe ... tempOrder is: ', tempOrder);
        // when there is a new order ... need to unsubscribe to the current lite item subscription and create a new one.
        if (this.theLineItemForCountSubscrption) {
          this.theLineItemForCountSubscrption.unsubscribe();
        }
        this.theLineItemForCountSubscrption = this.getLineItemsForOrder(tempOrder.uid).subscribe(returnedLineItems => {

          let tempItemsCount = 0;

          console.log('After checking for the line items ... we found these: ', returnedLineItems);
          returnedLineItems.forEach(tempLI => {
            console.log('The line item quantity = ', tempLI.quantity);
            tempItemsCount += tempLI.quantity;
          });

          this.numberOfItemsInCurrentOrder$.next(tempItemsCount);
          console.log('THE open order has ' + tempItemsCount + ' items in it !');
        });

      } else {
        // There is no order so item count = 0 - and remove the line items subscribe.
        if (this.theLineItemForCountSubscrption) {
          this.theLineItemForCountSubscrption.unsubscribe();
        }

        this.numberOfItemsInCurrentOrder$.next(0);
        console.log('THE open order has 0 (no) items in it !');
      }

    });

  }

  ngOnDestroy() {
    console.log('In the OnDestroy of the OrderMgmtService ... dont think this ever gets called for a service');
    if (this.theLineItemForCountSubscrption) {
      this.theLineItemForCountSubscrption.unsubscribe();
    }
    if (this.theOpenOrderSubscription) {
      this.theOpenOrderSubscription.unsubscribe();
    }
    if (this.theUserDataSubscription) {
      this.theUserDataSubscription.unsubscribe();
    }
  }

  private async checkForLocalOrderUid(): Promise<string | null> {

    let localOrderUidFound = false;
    let openOrderDocUid: string;

    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);

      if (key === 'localStoredOrderUid' && localStorage.getItem(key) !== '') {
        openOrderDocUid = localStorage.getItem(key);
        console.log('Found locally stored Order Id for non-logged in user ... and it is: ', openOrderDocUid);
        localOrderUidFound = true;
      }
    }

    // if not found then ... create one
    if (localOrderUidFound) {
      console.log('BROWSER HAS OPEN ORDER - return it !!');
      return openOrderDocUid;
    } else {
      console.log('NO OPEN ORDER ON THIS BROWSER return null');
      return null;
    }
  }

  getOpenOrdersForUid(theUserUid: string): Observable<OrderModel[]> {

    const OrdersCollection: AngularFirestoreCollection<OrderModel> = this.afs.collection('Orders', ref => ref.where('customerUserUID', '==', theUserUid).where('orderStatus', '==', 'open'));

    return OrdersCollection.valueChanges().pipe(map(items => {
      console.log('Value Changes has returned in the list of orders (from service) !!', items);
      if (items.length > 1) {
        console.log('ERROR - More than one open order for this user - THIS SHOULD NOT HAPPEN');
      }
      items.map(itemd => {
        itemd.timestampOrderEntered = (itemd.timestampOrderEntered as unknown as firebase.firestore.Timestamp).toDate();
        itemd.timestampShipping = (itemd.timestampShipping as unknown as firebase.firestore.Timestamp).toDate();
        itemd.timestampPaymentConfirmation = (itemd.timestampPaymentConfirmation as unknown as firebase.firestore.Timestamp).toDate();
        return itemd;
      });
      return items;
    }));
  }

  getJustTheUidForOpenOrdersForUser(theUserUid: string): Observable<any[]> {

    const OrdersCollection: AngularFirestoreCollection<any> = this.afs.collection('Orders', ref => ref.where('customerUserUID', '==', theUserUid).where('orderStatus', '==', 'open'));

    return OrdersCollection.valueChanges().pipe(map(items => {
      console.log('Value Changes has returned in the FULL list of orders (from service) !!', items);
      if (items.length > 1) {
        console.log('ERROR - More than one open order for this user - THIS SHOULD NOT HAPPEN');
      }
      // items.map(itemd => {
      //   const tempUid  = itemd.uid;
      //   const itemf: any = {};
      //   itemf.uid = tempUid;
      //   itemf.newThing = "WTF IS HERERE";
      //   console.log('You know where this is ... itemf = ', itemf);
      //   return itemf;
      // });

      // console.log('But what happened by here ?? - items is:  ', items);
      // return items;

      const returnArray: any[] = [];

      items.forEach(itemd => {
        const itemf: any = {};
        itemf.uid = itemd.uid;
        // console.log('You know where this is ... itemf = ', itemf);
        returnArray.push(itemf);
      });

      return returnArray;

    }));
  }

  getPlacedOrdersForUid(theUserUid: string): Observable<OrderModel[]> {

    const OrdersCollection: AngularFirestoreCollection<OrderModel> = this.afs.collection('Orders', ref => ref.where('customerUserUID', '==', theUserUid).where('orderId', '>', ''));

    return OrdersCollection.valueChanges().pipe(map(items => {
      console.log('Value Changes has returned in the list of orders (from service) !!', items);
      items.map(itemd => {
        itemd.timestampOrderEntered = (itemd.timestampOrderEntered as unknown as firebase.firestore.Timestamp).toDate();
        itemd.timestampShipping = (itemd.timestampShipping as unknown as firebase.firestore.Timestamp).toDate();
        itemd.timestampPaymentConfirmation = (itemd.timestampPaymentConfirmation as unknown as firebase.firestore.Timestamp).toDate();
        return itemd;
      });
      return items;
    }));
  }

  getOrderWithOrderId(theOrderId: string): Observable<OrderModel> {

    const OrdersCollection: AngularFirestoreCollection<OrderModel> = this.afs.collection('Orders', ref => ref.where('orderId', '==', theOrderId));

    return OrdersCollection.valueChanges().pipe(map(items => {
      console.log('Value Changes has returned in the list of orders (from service) !!', items);

      if (items.length > 1) {
        console.log('MORE THAN ONE ORDER FOUND !!  THIS SHOULD NOT HAPPEN');
      }

      items.map(itemd => {
        itemd.timestampOrderEntered = (itemd.timestampOrderEntered as unknown as firebase.firestore.Timestamp).toDate();
        itemd.timestampShipping = (itemd.timestampShipping as unknown as firebase.firestore.Timestamp).toDate();
        itemd.timestampPaymentConfirmation = (itemd.timestampPaymentConfirmation as unknown as firebase.firestore.Timestamp).toDate();
        return itemd;
      });
      return items[0];
    }));
  }

  getOrderWithUid(theOrderUid: string): Observable<OrderModel> {

    const OrderDoc: AngularFirestoreDocument<OrderModel> = this.afs.collection('Orders/').doc(theOrderUid);

    return OrderDoc.valueChanges().pipe(map(itemd => {
      console.log('Value Changes has returned in the single order (from service) !!', itemd);

      if (itemd) {
        itemd.timestampOrderEntered = (itemd.timestampOrderEntered as unknown as firebase.firestore.Timestamp).toDate();
        itemd.timestampShipping = (itemd.timestampShipping as unknown as firebase.firestore.Timestamp).toDate();
        itemd.timestampPaymentConfirmation = (itemd.timestampPaymentConfirmation as unknown as firebase.firestore.Timestamp).toDate();
        return itemd;
      } else {
        return null;
      }

    }));
  }


  getLineItemsForOrder(theOrderUid: string): Observable<CartLineItemModel[]> {

    const LineItemsCollection: AngularFirestoreCollection<CartLineItemModel> = this.afs.collection('Orders/' + theOrderUid + '/itemsInOrder');

    return LineItemsCollection.valueChanges().pipe(map(items => {
      console.log('Value Changes has returned in the list of line items (from service) !!', items);
      items.map(itemd => {
        itemd.timestampAddedToCart = (itemd.timestampAddedToCart as unknown as firebase.firestore.Timestamp).toDate();
        // itemd.timestampLastUpdated = (itemd.timestampLastUpdated as unknown as firebase.firestore.Timestamp).toDate();
        return itemd;
      });
      return items;
    }));


  }

  async createNewOrder(forUserUid: string): Promise<any> {

    const OrdersCollection: AngularFirestoreCollection<OrderModel> = this.afs.collection('Orders'); // reference

    const newUid = this.afs.createId();

    const newOrder: OrderModel = new OrderModel();

    newOrder.customerUserUID = forUserUid;
    newOrder.orderStatus = 'open';
    newOrder.uid = newUid;

    const addResult = await OrdersCollection.doc(newUid).set({ ...newOrder });
    console.log('in Service creating new Order and the create result is: ', addResult);

    return newUid;
  }

  async updateOrderByUid(theOrderUid: string, theUpdateJSON: any): Promise<any> {
    const theOrderDoc: AngularFirestoreDocument<OrderModel> = this.afs.doc('Orders/' + theOrderUid); // reference

    return theOrderDoc.update(theUpdateJSON) // check returned values for errors
      .then(function () {
        console.log('The Order Data was successfully updated in the backend DB');
        return;
      })
      .catch(function (error) {
        console.log('there was an error updating the Order Data in the backend DB, the error is: ', error);
        throw (error);
      });
  }

  async updateWholeOrder(theOrderInput: OrderModel): Promise<any> {
    const theOrderDoc: AngularFirestoreDocument<OrderModel> = this.afs.doc('Orders/' + theOrderInput.uid); // reference

    return theOrderDoc.set(theOrderInput) // check returned values for errors
      .then(function () {
        console.log('The Whole order was successfully updated in the backend DB');
        return;
      })
      .catch(function (error) {
        console.log('there was an error updating the Whole order in the backend DB, the error is: ', error);
        throw (error);
      });
  }

  async updateOrderAndPlace(theOrderInput: OrderModel): Promise<any> {

    await this.updateWholeOrder(theOrderInput);

    // const theChargeResult = await this.afFun.httpsCallable('stripeProcessPaymentForOrder')({ orderUid: theOrderInput.uid }).toPromise();

    return await this.afFun.httpsCallable('stripeProcessPaymentForOrder')({ orderUid: theOrderInput.uid }).toPromise();

  }

  async generatePDFReceiptForOrder(theOrderUid: string): Promise<any> {

    // const theChargeResult = await this.afFun.httpsCallable('stripeProcessPaymentForOrder')({ orderUid: theOrderInput.uid }).toPromise();

    return await this.afFun.httpsCallable('callableGeneratePDFforOrder')({ orderUid: theOrderUid }).toPromise();

  }

  async resendEmailReceiptForOrder(theOrderUid: string): Promise<any> {

    return await this.afFun.httpsCallable('resendOrderRecipt')({ orderUid: theOrderUid }).toPromise();

  }


  async cancelOrder(theOrderUid: string) {

    const tempJSON: any = {};
    tempJSON.orderStatus = 'cancelled';

    const updateResult = await this.updateOrderByUid(theOrderUid, tempJSON);

  }

  async addLineItemToOpenCartForUser(theUserUid: string, theItem: CartLineItemModel): Promise<any> {

    let openOrderDocUid: string;

    if (theUserUid === 'no user') {
      // No user is logged in ... so get check local storage for an order UID and if found then use it if not then create it.
      let localOrderUidFound = false;

      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);

        if (key === 'localStoredOrderUid' && localStorage.getItem(key) !== '') {
          openOrderDocUid = localStorage.getItem(key);
          console.log('Found locally stored Order Id for non-logged in user ... and it is: ', openOrderDocUid);
          localOrderUidFound = true;
        }
      }

      // if not found then ... create one
      if (!localOrderUidFound) {
        openOrderDocUid = await this.createNewOrder('no user');
        console.log('Created order for non logged in "no user" ... and the order UID is: ', openOrderDocUid);
        localStorage.setItem('localStoredOrderUid', openOrderDocUid);

        // I think I need to manage this subscription
        if (this.theOpenOrderSubscription) {
          this.theOpenOrderSubscription.unsubscribe();
        }
        console.log('set order id behaviour subject 5 !!!!');
        this.theOpenOrderSubscription = this.getOrderWithUid(openOrderDocUid).subscribe(tempOrder => {
          if (tempOrder.orderStatus === 'open') {
            this.currentOpenOrderUserOrNot$.next(tempOrder);
          } else {
            this.currentOpenOrderUserOrNot$.next(null);
          }
        });
      }
    } else {

      const OpenOrdersCollection: AngularFirestoreCollection<OrderModel> = this.afs.collection('Orders', ref => ref.where('customerUserUID', '==', theUserUid).where('orderStatus', '==', 'open'));

      const openOrders1 = await this.getJustTheUidForOpenOrdersForUser(theUserUid).pipe(
        take(1)
      ).toPromise();

      if (openOrders1.length < 1) {
        console.log('there is no open order for this uid ... create one and try again.');
        await this.createNewOrder(theUserUid);

        const openOrders2 = await this.getJustTheUidForOpenOrdersForUser(theUserUid).pipe(
          take(1)
        ).toPromise();

        console.log('In Add Item to Open Cart for User and the OpenOrders2 collection is: ', openOrders2);
        openOrderDocUid = openOrders2[0].uid;

        // I think I need to manage this subscription
        if (this.theOpenOrderSubscription) {
          this.theOpenOrderSubscription.unsubscribe();
        }
        console.log('set order id behaviour subject 5 !!!!');
        this.theOpenOrderSubscription = this.getOrderWithUid(openOrderDocUid).subscribe(tempOrder => {
          if (tempOrder.orderStatus === 'open') {
            this.currentOpenOrderUserOrNot$.next(tempOrder);
          } else {
            this.currentOpenOrderUserOrNot$.next(null);
          }
        });

      } else {
        console.log('In Add Item to Open Cart for User and the OpenOrders collection is: ', openOrders1);
        openOrderDocUid = openOrders1[0].uid;
      }

    }

    const theItemsCollection: AngularFirestoreCollection<CartLineItemModel> = this.afs.collection('Orders/' + openOrderDocUid + '/itemsInOrder'); // reference
    const theExistingLineItems: CartLineItemModel[] = await this.getLineItemsForOrder(openOrderDocUid).pipe(
      take(1)
    ).toPromise();

    let indexOfFoundMatch = -1;
    if (theExistingLineItems.length > 0) {
      for (let xi = 0; xi < theExistingLineItems.length; xi++) {
        if (theExistingLineItems[xi].sku === theItem.sku) {
          indexOfFoundMatch = xi;
        }
      }
    }

    if (indexOfFoundMatch >= 0) {
      const theQuanJSON: any = {};
      theQuanJSON.quantity = theExistingLineItems[indexOfFoundMatch].quantity + theItem.quantity;

      await this.updateLineItemInOrder(openOrderDocUid, theExistingLineItems[indexOfFoundMatch].uid, theQuanJSON);
      return theExistingLineItems[indexOfFoundMatch].uid;

    } else {

      const newUid = this.afs.createId();
      theItem.uid = newUid;
      console.log('BEFORE Adding a cart line item to the sub collection and the line item is: ', theItem);
      const addResult = await theItemsCollection.doc(newUid).set({ ...theItem });
      console.log('Add a cart line item to the sub collection and the result is: ', addResult);

      return newUid;
    }
  }

  async updateLineItemInOrder(theOrderUid: string, theLineItemUid: string, theUpdateJSON: any) {

    const theLineItemDocument: AngularFirestoreDocument<CartLineItemModel> = this.afs.doc('Orders/' + theOrderUid + '/itemsInOrder/' + theLineItemUid); // reference

    theUpdateJSON.timestampLastUpdated = new Date();

    return theLineItemDocument.update(theUpdateJSON) // check returned values for errors
      .then(function () {
        console.log('The Line Item ' + theLineItemUid + ' was successfully updated in the backend DB');
        return;
      })
      .catch(function (error) {
        console.log('there was an error updating the Line Itemin the backend DB, the error is: ', error);
        throw (error);
      });
  }

  async deleteLineItemInOrder(theOrderUid: string, theLineItemUid: string) {

    const theLineItemDocument: AngularFirestoreDocument<CartLineItemModel> = this.afs.doc('Orders/' + theOrderUid + '/itemsInOrder/' + theLineItemUid); // reference

    theLineItemDocument.delete();
  }

  async deepDeleteOfOrder(theOrderUid: string) {
    // this should only be called when an order has been merged into another order ... it may orphan some street address objects.

    const localOrderLineItems: CartLineItemModel[] = await this.getLineItemsForOrder(theOrderUid).pipe(
      take(1)
    ).toPromise();

    for (let liX = 0; liX < localOrderLineItems.length; liX++) {
      const tempLineItem = localOrderLineItems[liX];
      await this.deleteLineItemInOrder(theOrderUid, tempLineItem.uid);
    }

    const theOrderDocument: AngularFirestoreDocument<OrderModel> = this.afs.doc('Orders/' + theOrderUid); // reference

    await theOrderDocument.delete();

    return;
  }

}
