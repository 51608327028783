import { Component } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { OrderManagementService } from '../payments/order-management-service/order-management.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'nav-sample-bas',
  templateUrl: './nav-sample-bas.component.html',
  styleUrls: ['./nav-sample-bas.component.scss']
})
export class NavSampleBasComponent {

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  constructor(
    private breakpointObserver: BreakpointObserver,
    public authService: AuthService,
    public orderMgmtService: OrderManagementService
    ) {}

}
// THIS IS THE ONE THAT IS USED !!

