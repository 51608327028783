import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { Observable } from 'rxjs';
import { OrderModel } from '../payments/models/order-model';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { OrderManagementService } from '../payments/order-management-service/order-management.service';
import { AuthService } from '../auth/auth.service';
import { GlobalUtilitiesService } from '../utils/global-utils.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-orders-list',
  templateUrl: './orders-list.component.html',
  styleUrls: ['./orders-list.component.scss']
})
export class OrdersListComponent implements OnInit {

  listSubscription;

  displayedColumns = ['billedToName', 'orderId', 'orderTotalCharge', 'dateOrdered', 'orderStatus', 'details'];
  dataSource: MatTableDataSource<any>;

  orderStatus: string;

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  constructor(
    private afs: AngularFirestore,
    private router: Router,
    public theRoute: ActivatedRoute,
    private orderMgmtService: OrderManagementService,
    public authService: AuthService,
    public gu: GlobalUtilitiesService
  ) { }

  ngOnInit() {

        this.InitializeCode();

  }


  InitializeCode() {

    this.listSubscription = this.orderMgmtService.getPlacedOrdersForUid(this.authService.currentUserId())
    .subscribe(orderssData => {
      this.dataSource = new MatTableDataSource(orderssData);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      // This is where I do my custome sorting !!
      this.dataSource.sortingDataAccessor = (item, property) => {

        // console.log('In the sorting Data Accessor - when does this happen ??');
        // console.log('What is the item ??: ', item);
        // console.log('What is the property ??: ', property);
        // return item[property];

        switch (property) {

          case 'billedToName': return item.customerName;
          case 'dateOrdered': return item.timestampOrderEntered;
          default: return item[property];
        }
      };
    });

  }

  openDetailsPage(theOrderId: string) {
    this.router.navigate(['/orders/' + theOrderId]);
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  trackByUid(index, item) {
    return item.uid;
  }

}
